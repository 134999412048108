import Splide from '@splidejs/splide';

import BaseView from '../../../js/base-view';

const SELECTOR_PAGINATION_CURRENT = '.articles-slider__pagination__current';
const SELECTOR_PAGINATION_TOTAL = '.articles-slider__pagination__total';

export default class ArticleSlider extends BaseView {
	/**
	 * Init function
	 */
	initialize() {
		// Get references to DOM elements & view
		this.refs = {
			paginationCurrent: this.element.querySelector(
				SELECTOR_PAGINATION_CURRENT
			),
			paginationTotal: this.element.querySelector( SELECTOR_PAGINATION_TOTAL ),
		};

		this.slider = new Splide( '.splide', {
			pagination: false,
			perPage: 1,
			gap: '2.5vw',
			mediaQuery: 'min',
			breakpoints: {
				600: {
					perPage: 2,
				},
				960: {
					gap: 24,
				},
				1024: {
					perPage: 3,
				},
			},
		} );

		// Update pagination when slider is mounted
		this.slider.on( 'mounted', () => {
			this.updatePagination( this.slider );
		} );

		// Update pagination when moving to a new slide
		this.slider.on( 'moved', () => {
			this.updatePagination( this.slider );
		} );

		this.slider.on( 'resized', () => {
			this.updatePagination( this.slider );
		} );

		this.slider.mount();
	}

	/**
	 * Update pagination numbers
	 *
	 * @param {Splide} splide - The Splide instance
	 */
	updatePagination( splide ) {
		const { perPage } = splide.options;
		const currentPage = Math.ceil( ( splide.index + 1 ) / perPage );
		const totalPages = Math.ceil( splide.length / perPage );

		this.refs.paginationCurrent.textContent = currentPage;
		this.refs.paginationTotal.textContent = totalPages;
	}

	destroy() {
		super.destroy();

		this.slider.destroy();
	}
}
