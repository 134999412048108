import { gsap } from 'gsap';

import BaseView from '../../../js/base-view';
import { CustomEase } from '../../../js/utils/gsap/CustomEase';

import HeaderVideo from './header--video';

const CLASS_ANIMATING = 'is-animating';
const CLASS_ANIMATED = 'is-animated';

const UPTITLE_SELECTOR = '[data-anim-uptitle]';
const TITLE_SELECTOR = '.header__title';
const MEDIA_SELECTOR = '.header__media,.header__img';
const MEDIA_WRAP_SELECTOR = '.header__media-wrap,.header__img-wrap';
const CLIPPED_MEDIA_SELECTOR = '[data-clipped-image]';
const DESC_SELECTOR = '.header__description';
const BUTTONS_SELECTOR = '.header__buttons';
const DETAILS_SELECTOR = '[data-anim-details]';
const COVER_IMAGE_SELECTOR = '[data-cover-image]';

const HEADER_FORMATION_MODIFIER = 'header--formation';

gsap.registerPlugin( CustomEase );

CustomEase.create( 'custom-ease-1', '0.36,0,0.03,1' );
CustomEase.create( 'custom-ease-2', '0.45,0,0.08,1' );

export default class Header extends BaseView {
	initialize() {
		this.refs = {
			uptitle: this.getScopedElement( UPTITLE_SELECTOR ),
			title: this.getScopedElement( TITLE_SELECTOR ),
			media: this.getScopedElement( MEDIA_SELECTOR ),
			clippedMedia: this.getScopedElement( CLIPPED_MEDIA_SELECTOR ),
			desc: this.getScopedElement( DESC_SELECTOR ),
			buttons: this.getScopedElement( BUTTONS_SELECTOR ),
			details: this.getScopedElement( DETAILS_SELECTOR ),
			coverImage: this.getScopedElement( COVER_IMAGE_SELECTOR ),
		};

		this.state = {
			playReceived: false,
			animationReady: false,
			animations: {},
			isFormation: this.element.classList.contains( HEADER_FORMATION_MODIFIER ),
		};

		this.props = {
			isSmall: ! viewport_service.isComputer(),
		};

		// Add banner reference if this is a formation header
		if ( this.state.isFormation ) {
			this.refs.banner = this.element.nextElementSibling;
			this.setupIntersectionObserver();
		}

		this.video = new HeaderVideo( this.getScopedElement( MEDIA_WRAP_SELECTOR ) );

		this.bindEvents();
		this.setAnimationInitial()
			.then( this.setReady.bind( this ) );
	}

	bindEvents() {
		this.onCompleteHandler = this.handleAnimComplete.bind( this );
		this.playAnimationHandler = this.handlePlayAnimation.bind( this );
		this.on( 'header:play-animation', this.playAnimationHandler, true );
		this.on( 'transition:end', this.playAnimationHandler, true );
	}

	handlePlayAnimation() {
		this.state.playReceived = true;
		if ( this.state.animationReady ) {
			this.animate();
		}
	}

	/**
	 * When an animation is completed,
	 * remove the Tween from the state
	 *
	 * @param {string} name Name of the Tween animation
	 */
	handleAnimComplete( name ) {
		delete this.state.animations[ name ];

		if ( Object.keys( this.state.animations ).length === 0 ) {
			this.element.classList.remove( CLASS_ANIMATING );
			this.element.classList.add( CLASS_ANIMATED );
		}
	}

	/**
	 * Define the initial state of the animation for each DOM element
	 */
	setAnimationInitial() {
		return new Promise( ( resolve ) => {
			if ( this.refs.coverImage ) {
				this.state.animations.coverImage = gsap.timeline( { paused: true } )
					.from( this.refs.coverImage, {
						opacity: 0,
						duration: 1,
						ease: 'custom-ease-2',
						delay: 0,
						onComplete: this.onCompleteHandler,
						onCompleteParams: [ 'coverImage' ],
					} );
			}

			if ( this.refs.uptitle ) {
				// Uptitle OPACITY & POSITION
				this.state.animations.uptitleOpacity = gsap.timeline( { paused: true } )
					.from( this.refs.uptitle, {
						opacity: 0,
						duration: 0.5,
						delay: 0.1,
						onComplete: this.onCompleteHandler,
						onCompleteParams: [ 'uptitleOpacity' ],
					} );
				this.state.animations.uptitlePosition = gsap.timeline( { paused: true } )
					.from( this.refs.uptitle, {
						y: '10vh',
						duration: 0.75,
						ease: 'custom-ease-1',
						onComplete: this.onCompleteHandler,
						onCompleteParams: [ 'uptitlePosition' ],
					} );
			}

			// Title OPACITY & POSITION
			this.state.animations.titleOpacity = gsap.timeline( { paused: true } )
				.from( this.refs.title, {
					duration: 0.5,
					opacity: 0,
					delay: 0.15,
					onComplete: this.onCompleteHandler,
					onCompleteParams: [ 'titleOpacity' ],
				} );
			this.state.animations.titlePosition = gsap.timeline( { paused: true } )
				.from( this.refs.title, {
					y: '10vh',
					duration: 0.75,
					delay: 0.05,
					ease: 'custom-ease-1',
					onComplete: this.onCompleteHandler,
					onCompleteParams: [ 'titlePosition' ],
				} );

			if ( this.refs.clippedMedia ) {
				// Clipped Image OPACITY, CLIP-PATH
				this.state.animations.clippedMedia = gsap.timeline( { paused: true } )
					.from( this.refs.clippedMedia, {
						clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
						duration: 0.8,
						ease: 'custom-ease-2',
						onComplete: this.onCompleteHandler,
						onCompleteParams: [ 'clippedMedia' ],
					} );

				this.state.animations.clippedMediaImage = gsap.timeline( { paused: true } )
					.from( `${ CLIPPED_MEDIA_SELECTOR } img`, {
						scale: 1.05,
						duration: 0.8,
						ease: 'custom-ease-2',
						onComplete: this.onCompleteHandler,
						onCompleteParams: [ 'clippedMediaImage' ],
					} );
			}
			else if ( this.refs.media ) {
				// Image OPACITY, POSITION & SCALE
				this.state.animations.media = gsap.timeline( { paused: true } )
					.from( this.refs.media, {
						y: ( this.props.isMobile ? '10vh' : '20vh' ),
						opacity: 0,
						duration: 0.8,
						ease: 'custom-ease-2',
						delay: 0.15,
						onComplete: this.onCompleteHandler,
						onCompleteParams: [ 'media' ],
					} );
			}

			if ( this.refs.desc ) {
				this.state.animations.descOpacity = gsap.timeline( { paused: true } )
					.from( this.refs.desc, {
						opacity: 0,
						duration: 0.5,
						delay: 0.2,
						onComplete: this.onCompleteHandler,
						onCompleteParams: [ 'descOpacity' ],
					} );
				this.state.animations.descPosition = gsap.timeline( { paused: true } )
					.from( this.refs.desc, {
						y: '10vh',
						duration: 0.75,
						ease: 'custom-ease-1',
						delay: 0.1,
						onComplete: this.onCompleteHandler,
						onCompleteParams: [ 'descPosition' ],
					} );
			}

			if ( this.refs.buttons ) {
				this.state.animations.buttonsOpacity = gsap.timeline( { paused: true } )
					.from( this.refs.buttons, {
						opacity: 0,
						duration: 0.5,
						delay: 0.3,
						onComplete: this.onCompleteHandler,
						onCompleteParams: [ 'buttonsOpacity' ],
					} );
				this.state.animations.buttonsPosition = gsap.timeline( { paused: true } )
					.from( this.refs.buttons, {
						y: '10vh',
						duration: 0.75,
						ease: 'custom-ease-1',
						delay: 0.2,
						onComplete: this.onCompleteHandler,
						onCompleteParams: [ 'buttonsPosition' ],
					} );
			}


			if ( this.refs.details ) {
				this.state.animations.detailsOpacity = gsap.timeline( { paused: true } )
					.from( this.refs.details, {
						opacity: 0,
						duration: 0.5,
						delay: 0.2,
						onComplete: this.onCompleteHandler,
						onCompleteParams: [ 'detailsOpacity' ],
					} );
				this.state.animations.detailsPosition = gsap.timeline( { paused: true } )
					.from( this.refs.details, {
						y: '10vh',
						duration: 0.75,
						ease: 'custom-ease-1',
						delay: 0.1,
						onComplete: this.onCompleteHandler,
						onCompleteParams: [ 'detailsPosition' ],
					} );
			}

			resolve();
		} );
	}

	/**
	 * Starts each registered animation
	 */
	animate() {
		this.element.classList.add( CLASS_ANIMATING );
		Object.keys( this.state.animations ).forEach( ( name ) => {
			const tl = this.state.animations[ name ];
			tl.play();
		} );

		// Starts the video
		if ( this.video ) {
			this.video.start();
		}
	}

	setReady() {
		this.state.animationReady = true;
		this.element.classList.remove( 'animation-not-ready' );
		this.trigger( 'header:animation-ready' );

		if ( this.state.playReceived ) {
			this.animate();
		}
	}

	setupIntersectionObserver() {
		const options = {
			threshold: 0,
			rootMargin: '-150px 0px 0px',
		};

		this.observer = new IntersectionObserver( ( entries ) => {
			entries.forEach( ( entry ) => {
				if ( this.refs.banner ) {
					// When header is visible (entry.isIntersecting is true), banner should be hidden
					this.refs.banner.classList.toggle( 'is-visible', ! entry.isIntersecting );
				}
			} );
		}, options );

		this.observer.observe( this.element );
	}

	destroy() {
		if ( this.observer ) {
			this.observer.disconnect();
		}
		this.video.destroy();
		delete this.video;
		super.destroy();
	}
}
