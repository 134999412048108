/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { isEmpty } from 'lodash-es';

import BaseView from '../../../js/base-view';
import requester from '../../../js/utils/requester';

import SearchBar from '../../organisms/search-bar/search-bar';
import ResultList from '../../organisms/result-list/result-list';

const SELECTOR_SEARCH_BAR = '[data-search-bar]';
const SELECTOR_RESULT_LIST = '[data-result-list]';

const DEFAULT_CONFIG = {
	json_base: 'http://localhost/wp-json',
	nbPerPage: 9,
	lang: 'fr',
	ot: null, // "ot" for "Auth",
};

const DEFAULT_STATE = {
	page: 1,
	searchValue: null,
	searchFilter: null,
	nbResults: 0,
};

const CURRENT_LANG = (
	( window.supt && window.supt.lang ) ? window.supt.lang : DEFAULT_CONFIG.lang
);

export default class PagePublicationList extends BaseView {
	// #######################
	// #region Init
	// #######################

	initialize() {
		this.config = { ...DEFAULT_CONFIG, ...window.supt.publications, lang: CURRENT_LANG };

		this.refs = {
			searchBar: new SearchBar( this.element.querySelector( SELECTOR_SEARCH_BAR ) ).init(),
			publicationList: new ResultList( this.element.querySelector( SELECTOR_RESULT_LIST ) )
				.init( { renderCard: this.renderCard } ),
		};

		this.state = new Proxy( { ...DEFAULT_STATE }, { set: this.stateChange.bind( this ) } );

		this.bindEvents();
	}

	/**
	 * Trap handler for the state object
	 *
	 * @param {Object} state Current state object
	 * @param {string} property The name of the property to set in the state
	 * @param {any} value The new value of the property to set
	 *
	 * @return {boolean} Indicate wether or not the assignment succeeded
	 */
	stateChange( state, property, value ) {
		if ( state[ property ] === value ) {
			return true;
		}
		state[ property ] = value;

		switch ( property ) {
			case 'searchValue':
				state.page = 1;
				state.searchFilter = null;
				break;

			case 'searchFilter':
				state.page = 1;
				break;

			case 'nbResults':
				this.refs.searchBar.updateInputNbResults( value );
				break;

			case 'page':
			default:
				// nothing to do
				break;
		}
		return true;
	}

	bindEvents() {
		this.on( 'search:change', SELECTOR_SEARCH_BAR, this.onSearchChange.bind( this ) );
		this.on( 'search:submit', SELECTOR_SEARCH_BAR, this.onSubmit.bind( this ) );

		this.on( 'next-page', SELECTOR_RESULT_LIST, this.onNextPage.bind( this ) );
	}

	// #######################
	// #endregion
	// #######################

	// #######################
	// #region Event Handlers
	// #######################

	onSearchChange( { detail } ) {
		this.state.searchValue = detail;

		this.query( true );
	}

	onSubmit( { detail } ) {
		this.state.searchValue = detail.text;
		this.state.searchFilter = detail.filter;

		this.query();
	}

	onNextPage() {
		this.state.page += 1;

		this.query();
	}

	// #######################
	// #endregion
	// #######################

	// #######################
	// #region ACTIONS
	// #######################

	query( isSuggestion = false ) {
		const terms = this.getTerms();
		const params = {
			type: 'post',
			subtype: 'publication',
			per_page: this.config.nbPerPage,
			page: this.state.page,
			search: this.state.searchValue,
			suggest: isSuggestion,
			...terms,
		};

		this.state.queryId = performance.now();

		requester( {
			name: 'pagePublicationList:query',
			url: `${ this.config.json_base }/supt/v1/publications`,
			method: 'get',
			params,
			id: this.state.queryId,
		} )
			.then( ( { id, data } ) => {
				// Do not process if it's not the latest request
				if ( id !== this.state.queryId ) {
					return;
				}

				if ( isSuggestion ) {
					this.refs.searchBar.updateInputSuggestions(
						data.items.map( ( item ) => ( { value: item.data.title } ) )
					);
				}
				else {
					// Only show results number when not suggesting
					this.state.nbResults = ( isEmpty( this.state.searchValue ) && isEmpty( terms ) )
						? 0
						: data.total;

					const hasMoreItemsToLoad = data.max_pages > this.state.page;
					this.refs.publicationList[ ( this.state.page > 1 ) ? 'addPage' : 'setList' ]( data.items, ! hasMoreItemsToLoad );

					const filterTypes = {
						publication_category: data.aggregations.publication_category,
						publication_author: data.aggregations.publication_author,
						publication_date: data.aggregations.publication_date.map( ( term ) => term.value ),
					};
					Object.entries( filterTypes ).forEach( ( [ filterType, items ] ) => {
						this.refs.searchBar.updateFilters( filterType, items );
					} );
				}
			} );
	}

	getTerms() {
		const terms = {};
		if ( ! isEmpty( this.state.searchFilter ) ) {
			Object.keys( this.state.searchFilter )
				.filter( ( name ) => ! isEmpty( this.state.searchFilter[ name ] ) )
				.forEach( ( name ) => {
					terms[ name ] = this.state.searchFilter[ name ];
				} );
		}

		return terms;
	}

	// TODO: Keep in sync with card-publication
	renderCard( { modifiers = [], data = {} } ) {
		return `<li class="appear-enter">
		<article class="card-publication${ data.link?.href ? ' card-publication--link' : '' } ${ modifiers.join( ' ' ) } ">
			<div class="card-publication__inner">
				<div class="card-publication__header">
					<span class="card-publication__icon">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
							<path d="M23 29.5L29 23.4998M23 29.5H3V3.49976H29V23.4998M23 29.5V23.5L29 23.4998M7 13.5005H25M7 18.5005H19M7 8.50049H25" stroke="black" stroke-width="2"></path>
						</svg>
					</span>
					<p class="card-publication__title">${ data.title }</p>
				</div>
				<div class="card-publication__content">
					<ul class="card-publication__details">
						${ data.authors ? `<li class="card-publication__detail-item">
							<span class="card-publication__detail-title">{{ pll__('Auteurs') }}</span>
							<span class="card-publication__detail-desc">${ data.authors.map( ( author ) => `<a href="${ author.link }">${ author.name }</a>` ).join( ', ' ) }</span>
						</li>` : '' }
						${ data.date ? `<li class="card-publication__detail-item">
							<span class="card-publication__detail-title">{{ pll__('Date') }}</span>
							<span class="card-publication__detail-desc">${ data.date }</span>
						</li>` : '' }
					</ul>
				</div>
				${ data.link ? `<div class="card-publication__link-wrapper">
					<a class="card-publication__link" href="${ data.link.href }" ${ data.link.attrs }>
						<span>${ data.link.title }</span>
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
							<path d="M19.5 12.0421L12.5 5M19.5 12.0421L12.5 19M19.5 12.0421L4 12.0421" stroke="currentColor" stroke-width="1.5"></path>
						</svg>
					</a>
				</div>` : '' }
			</div>
		</article></li>`;
	}

	// #######################
	// #endregion
	// #######################
}
