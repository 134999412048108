import BaseView from '../../../js/base-view';

import PageArchivePosts from './page-archive--posts';
import PageArchiveEvents from './page-archive--events';
import PageArchivePublications from './page-archive--publications';
import PageArchiveProjects from './page-archive--projects';

const PAGE_ARCHIVE_TYPES = {
	post: PageArchivePosts,
	event: PageArchiveEvents,
	publication: PageArchivePublications,
	project: PageArchiveProjects,
};

export default class PageArchive extends BaseView {
	initialize() {
		// get config from the global scope
		// NOTE: we do this because we need to pass json data from views (for the "query" parameter),
		//       and it's quite tricky and error-prone to pass it through html data-attributes
		//       (mostly because escaping is not consistent between js/php)
		if ( ! window?.supt?.archive ) {
			console.error( 'Could not load config' );
			return false;
		}

		const postType = window.supt.archive?.postType ?? 'post';
		const ArchivePageType = PAGE_ARCHIVE_TYPES[ postType ];

		this.refs = {
			archivePage: new ArchivePageType( this.element, this.global ),
		};

		this.refs.archivePage.initialize();
		return true;
	}
}
