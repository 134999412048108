import { isEmpty } from 'lodash-es';

import BaseView from '../../../js/base-view';
import requester from '../../../js/utils/requester';

import AlphaTabs from '../../molecules/alphabetical-tabs/alphabetical-tabs';
import SearchBar from '../../organisms/search-bar/search-bar';
import ResultList from '../../organisms/result-list/result-list';

const SELECTOR_SEARCH_BAR = '[data-search-bar]';
const SELECTOR_ALPHABETICAL_TABS = '[data-alphabetical-tabs]';
const SELECTOR_RESULT_LIST = '[data-result-list]';

const DEFAULT_CONFIG = {
	elasticHost: 'http://localhost:9200',
	index: 'profiles',
	nbPerPage: 24,
	baseUrlProfile: 'http://localhost/profile',
	lang: 'fr',
	ot: null, // "ot" for "Auth",
	profileImg: {
		uri: false,
		ext: 'jpg',
	},
};

const DEFAULT_STATE = {
	page: 1,
	letter: null,
	search: null,
	filters: {
		departments: [],
		modules: [],
		skills: [],
	},
	showNbResults: true,
};

const SUGGEST_ARGS = {
	lastname: 'fullname.suggest',
	firstname: 'full_firstname.suggest',
	position: 'position.suggest',
	departments: 'departments.name.suggest',
	skills: `skills.${ ( ( window.supt && window.supt.lang ) ? window.supt.lang : DEFAULT_CONFIG.lang ) }.suggest`,
	modules: `modules.${ ( ( window.supt && window.supt.lang ) ? window.supt.lang : DEFAULT_CONFIG.lang ) }.suggest`,
};

const QUERY_STRING_PARAMS = {
	departments: 'departements',
	modules: 'enseignements',
	skills: 'expertises',
	position: 'fonction',
};

export default class PageAnnuaire extends BaseView {
	// #######################
	// #region Init
	// #######################

	initialize() {
		// fetch config from the global scope
		// NOTE: we do this because we need to pass json data from views (for the "query" parameter),
		//       and it's quite tricky and error-prone to pass it through html data-attributes
		//       (mostly because escaping is not consistent between js/php)
		if ( ! window.supt || ! window.supt.directory ) {
			console.error( 'Could not load config' );
			return false;
		}

		this.config = {
			...DEFAULT_CONFIG,
			...window.supt.directory,
			// 24 on desktop, 12 on mobile
			nbPerPage:
				window.innerWidth > 1024
					? window.supt.directory.nbPerPage * 2
					: window.supt.directory.nbPerPage,
		};
		if ( window.supt && window.supt.lang ) {
			this.config.lang = window.supt.lang;
		}

		this.renderCard = this.renderCard.bind( this );

		const listEl = this.element.querySelector( SELECTOR_RESULT_LIST );
		this.refs = {
			searchBar: new SearchBar( this.element.querySelector( SELECTOR_SEARCH_BAR ) ).init(),
			alphaTabs: new AlphaTabs( this.element.querySelector( SELECTOR_ALPHABETICAL_TABS ) ).init(),
			profileList: new ResultList( listEl ).init( { renderCard: this.renderCard } ),
		};

		this.state = new Proxy(
			this.getInitialState(),
			{ set: this.stateChange.bind( this ) },
		);

		this.departmentsItems = this.getFilterItems( 'departments' );
		this.modulesItems = this.getFilterItems( 'modules' );
		this.skillsItems = this.getFilterItems( 'skills' );

		this.bindEvents();

		this.query();

		return true;
	}

	getInitialState() {
		const queryArgs = new URLSearchParams( window.location.search );

		return {
			...DEFAULT_STATE,
			search: queryArgs.get( 'q' ) ?? '',
			filters: {
				departments: queryArgs.get( 'departements' )?.split( ',' ) ?? [],
				modules: queryArgs.get( 'enseignements' )?.split( ',' ) ?? [],
				skills: queryArgs.get( 'expertises' )?.split( ',' ) ?? [],
			},
			letter: queryArgs.get( 'lettre' ) ?? null,
		};
	}

	/**
	 * Trap handler for the state object
	 *
	 * @param {Object} state Current state object
	 * @param {string} property The name of the property to set in the state
	 * @param {*} value The new value of the property to set
	 *
	 * @return {boolean} Indicate wether or not the assignment succeeded
	 */
	stateChange( state, property, value ) {
		/* eslint-disable no-param-reassign */
		if ( state[ property ] === value ) {
			return true;
		}
		state[ property ] = value;

		switch ( property ) {
			case 'letter':
				state.page = 1;
				break;

			case 'search':
				state.page = 1;
				state.letter = null;
				state.filters = null;
				break;

			case 'filters':
				state.page = 1;
				state.letter = null;
				break;

			case 'page':
			default:
				// nothing to do
				break;
		}
		return true;
	}

	bindEvents() {
		this.on( 'search:change', SELECTOR_SEARCH_BAR, this.onSearchChange.bind( this ) );
		this.on( 'search:submit', SELECTOR_SEARCH_BAR, this.onSubmit.bind( this ) );

		this.on( 'change', SELECTOR_ALPHABETICAL_TABS, this.onLetterChange.bind( this ) );

		this.on( 'next-page', SELECTOR_RESULT_LIST, this.onNextPage.bind( this ) );
	}

	destroy() {
		this.refs.searchBar.destroy();
		this.refs.alphaTabs.destroy();
		this.refs.profileList.destroy();

		super.destroy();
	}

	getFilterItems( id, callback = null ) {
		const options = this.element.querySelectorAll( `[data-filter-select]#${ id } li[role="option"]` );

		return Array.from( options ).reduce( ( acc, o ) => ( {
			...acc,
			[ o.dataset.value ]: callback ? callback( o ) : o.textContent.trim(),
		} ), {} );
	}

	// #######################
	// #endregion
	// #######################

	// #######################
	// #region Event Handlers
	// #######################

	onSearchChange( { detail } ) {
		this.state.search = detail;

		this.suggest();
	}

	onSubmit( { detail } ) {
		this.state.search = detail.text;
		this.state.filters = detail.filters;

		this.query();
	}

	onLetterChange( { detail } ) {
		this.state.letter = detail;

		this.query();
	}

	onNextPage() {
		this.state.page += 1;

		this.query();
	}

	// #######################
	// #endregion
	// #######################

	// #######################
	// #region ACTIONS
	// #######################

	suggest() {
		const suggest = {};
		Object.keys( SUGGEST_ARGS ).forEach( ( key ) => {
			suggest[ key ] = {
				prefix: this.state.search,
				completion: {
					field: SUGGEST_ARGS[ key ],
					skip_duplicates: true,
					fuzzy: {
						fuzziness: 2,
					},
				},
			};
		} );

		let auth = null;
		if ( ! isEmpty( this.config.ot ) ) {
			auth = {
				username: this.config.ot.u,
				password: this.config.ot.p,
			};
		}

		requester( {
			name: 'pageAnnuaire:suggest',
			url: `${ this.config.elasticHost }/${ this.config.index }/_search`,
			method: 'post',
			auth,
			data: {
				query: this.buildQuery(),
				suggest,
				size: this.config.nbPerPage * 2,
				from: 0,
			},
		} )
			.then( ( { data } ) => {
				// Don't show results number when suggesting
				// this.refs.searchBar.updateInputNbResults( data.hits.total.value );

				const nowDate = new Date();
				nowDate.setHours( 0, 0, 0, 0 ); // reset to midnight
				const todayTime = nowDate.getTime();

				const suggestions = Object.keys( SUGGEST_ARGS ).map( ( key ) => {
					const filterCatName = QUERY_STRING_PARAMS[ key ];

					return data.suggest[ key ][ 0 ].options
						.filter( ( { _source: source } ) => {
							if ( ! source.end_contract ) {
								return true;
							}
							return ( new Date( source.end_contract ).getTime() > todayTime );
						} )
						.map( ( { score, _source: source, text } ) => ( {
							value: [ 'lastname', 'firstname' ].includes( key ) ? source.fullname : text,
							category: filterCatName,
							score,
						} ) );
				} ).flat().sort( ( a, b ) => b.score - a.score );

				this.refs.searchBar.updateInputSuggestions( suggestions );
			} );
	}

	query() {
		const query = this.buildQuery();
		const sort = [
			{ lastname: 'asc' },
		];

		// Do not sort by _score if no search nor filters
		let filters = null;
		if ( ! isEmpty( this.state.filters ) ) {
			filters = Object.keys( this.state.filters )
				.filter( ( k ) => ! isEmpty( this.state.filters[ k ] ) )
				.map( () => true );
		}

		if ( ! isEmpty( this.state.search ) || ! isEmpty( filters ) ) {
			sort.unshift( '_score' );
		}

		const fromIndex = this.config.nbPerPage * ( this.state.page - 1 );

		const args = {
			name: 'pageAnnuaire:query',
			url: `${ this.config.elasticHost }/${ this.config.index }/_search`,
			method: 'post',
			auth: this.config.ot,
			data: {
				query,
				size: this.config.nbPerPage,
				from: fromIndex,
				sort,
				aggregations: {
					letters: {
						terms: {
							size: 26,
							field: 'letter',
						},
					},
					departments: {
						terms: {
							field: 'departments.name.keyword',
							size: 1000,
						},
					},
					modules: {
						terms: {
							field: `modules.${ this.config.lang }.keyword`,
							size: 1000,
						},
					},
					skills: {
						terms: {
							field: `skills.${ this.config.lang }.keyword`,
							size: 1000,
						},
					},
				},
			},
		};

		if ( ! isEmpty( this.config.ot ) ) {
			args.auth = {
				username: this.config.ot.u,
				password: this.config.ot.p,
			};
		}

		requester( args )
			.then( async ( { data: { hits, aggregations } } ) => {
				const results = await Promise.all( hits.hits.map( this.getCardContext.bind( this ) ) );

				const hasMoreItemsToLoad = ( fromIndex + results.length < hits.total.value );

				this.refs.profileList[ ( this.state.page > 1 ? 'addPage' : 'setList' ) ]( results, ! hasMoreItemsToLoad );

				this.refs.searchBar
					.updateInputNbResults( this.state.showNbResults ? hits.total.value : 0 );

				if ( this.state.letter === null ) {
					this.refs.alphaTabs.setEnabledLetters(
						aggregations.letters.buckets.map( ( bucket ) => bucket.key ),
					);

					const filterTypes = {
						departments: this.departmentsItems,
						modules: this.modulesItems,
						skills: this.skillsItems,
					};

					Object.entries( filterTypes ).forEach( ( [ filterType, items ] ) => {
						const { buckets } = aggregations[ filterType ];
						const mappedValues = buckets.map( ( b ) => (
							Object.keys( items ).find( ( k ) => items[ k ] === b.key )
						) );

						this.refs.searchBar.updateFilters( filterType, mappedValues );
					} );
				}
			} );

		this.updateQueryString();
	}

	buildQuery() {
		// Empty
		const queryArgs = [];

		// Exclude profiles with no contract
		queryArgs.push( {
			bool: {
				should: [
					{ range: { end_contract: { gte: 'now' } } },
					{ bool: { must_not: { exists: { field: 'end_contract' } } } },
				],
			},
		} );

		// search text
		if ( ! isEmpty( this.state.search ) ) {
			const search = this.state.search.toLowerCase();
			const ln = search.length;
			const matcher = {
				query: search,
				fuzziness: ln <= 2 ? 0 : 1,
				prefix_length: ln <= 2 ? 0 : 1,
			};
			queryArgs.push( {
				bool: {
					should: [
						{ match: { fullname: matcher } },
						{ match: { position: matcher } },
						{ match: { 'department.name': matcher } },
						{ match: { [ `skills.${ this.config.lang }` ]: matcher } },
						{ match: { [ `modules.${ this.config.lang }` ]: matcher } },
						// Add prefix queries for short searches
						...( ln <= 2 ? [
							{ prefix: { fullname: search } },
							{ prefix: { position: search } },
							{ prefix: { 'department.name': search } },
							{ prefix: { [ `skills.${ this.config.lang }` ]: search } },
							{ prefix: { [ `modules.${ this.config.lang }` ]: search } },
						] : [] ),
					],
				},
			} );
		}

		// Filters selected
		let hasTerms = false;
		if ( ! isEmpty( this.state.filters?.departments ) ) {
			queryArgs.push( {
				terms: {
					'departments.name.keyword': this.state.filters.departments.map( ( v ) => this.departmentsItems[ v ] ),
				},
			} );
			hasTerms = true;
		}
		if ( ! isEmpty( this.state.filters?.modules ) ) {
			queryArgs.push( {
				terms: {
					[ `modules.${ this.config.lang }.keyword` ]: this.state.filters.modules.map( ( v ) => this.modulesItems[ v ] ),
				},
			} );
			hasTerms = true;
		}
		if ( ! isEmpty( this.state.filters?.skills ) ) {
			queryArgs.push( {
				terms: {
					[ `skills.${ this.config.lang }.keyword` ]: this.state.filters.skills.map( ( v ) => this.skillsItems[ v ] ),
				},
			} );
			hasTerms = true;
		}

		// lastname starts with letter X
		if ( ! isEmpty( this.state.letter ) ) {
			queryArgs.push( { match: { letter: this.state.letter } } );
		}

		// Update the showNbResults state
		// I cannot set this inside `stateChange` because state.filters is an object
		// and the proxy is not triggered by sub-objects
		this.state.showNbResults = ! ( isEmpty( this.state.search ) && isEmpty( hasTerms ) );

		return {
			bool: {
				must: queryArgs,
			},
		};
	}

	async getCardContext( { _source: source } ) {
		return {
			modifiers: [],
			data: {
				lastname: source.lastname,
				firstname: source.firstname,
				link: source?.slug ? `${ this.config.baseUrlProfile }/${ source.slug }/` : false,
				description: source.position,
				img: {
					src: `${ this.config.profileImg.uri }/${ source?.idisa ?? '1234' }.${ this.config.profileImg.ext }`,
				},
			},
		};
	}

	renderCard( { modifiers = [], data = {} } ) {
		const name = `${ data.lastname } ${ data.firstname }`.trim();
		const icon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M19.5 12.0421L12.5 5M19.5 12.0421L12.5 19M19.5 12.0421L4 12.0421" stroke="currentColor" stroke-width="1.5"></path>';
		return `
		<article class="card-profile ${ modifiers.join( ' ' ) }">
			<div class="card-profile__inner">
				<div class="card-profile__img">
					<img src="${ data.img.src }" />
				</div>
				<div class="card-profile__content">
					<h2 class="card-profile__title">
						${ data.link !== false ? `<a href="${ data.link }">${ name }</a>${ icon }` : `<span>${ name }</span>` }
					</h2>
					<p class="card-profile__description">${ data.description }</p>
				</div>
			</div>
		</article>`;
	}

	updateQueryString() {
		const queryStringParams = QUERY_STRING_PARAMS;
		const args = [];

		// search
		if ( ! isEmpty( this.state.search ) ) {
			args.push( `q=${ encodeURIComponent( this.state.search ) }` );
		}

		// filters
		Object.entries( this.state.filters ).forEach( ( [ key, value ] ) => {
			if ( ! isEmpty( value ) ) {
				args.push( `${ queryStringParams[ key ] }=${ value.sort().join( ',' ) }` );
			}
		} );

		// letter
		if ( ! isEmpty( this.state.letter ) ) {
			args.push( `lettre=${ this.state.letter }` );
		}

		const queryString = args.join( '&' );
		const newUrl = args.length ? `${ window.location.pathname }?${ queryString }` : window.location.pathname;
		if ( newUrl !== window.location.href ) {
			window.history.pushState( null, '', newUrl );
		}
	}

	// #######################
	// #endregion
	// #######################
}
