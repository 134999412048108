import { debounce } from 'lodash-es';

import BaseView from '../../../js/base-view';
import Filters from '../../molecules/filters/filters';

import SearchInput from '../../molecules/search-input/search-input';
import { MIN_SEARCH_LENGTH } from './constants';

const SELECTOR_INPUT = '[data-search-input]';
const SELECTOR_FILTERS = '[data-filters]';
const SELECTOR_FILTERS_SINGLE = `${ SELECTOR_FILTERS }.-single`;
const SELECTOR_FILTER = '.filter-select';

export default class SearchBar extends BaseView {
	init() {
		this.state = {
			text: null,
			filters: {},
		};

		const input = this.element.querySelector( SELECTOR_INPUT );
		const filters = this.element.querySelector( SELECTOR_FILTERS );
		const filtersSingle = this.element.querySelector( SELECTOR_FILTERS_SINGLE );
		this.refs = {
			input: input ? new SearchInput( input ).init() : null,
			filters: filters ? new Filters( filters ).init() : null,

			filtersSingle: filtersSingle ? new Filters( filtersSingle ).init() : null,
		};

		// Create debounced submit To avoid submitting the form on each filter
		this.debouncedSubmit = debounce( this.submit.bind( this ), 100 );

		this.bindEvents();

		return this; // For chaining
	}

	bindEvents() {
		this.on( 'filter:change', SELECTOR_FILTER, this.onFilterChange.bind( this ) );
		if ( this.refs.input ) {
			this.on( 'input:change', SELECTOR_INPUT, this.onInputChange.bind( this ) );
			this.on( 'input:submit', SELECTOR_INPUT, this.onInputSubmit.bind( this ) );
		}
	}

	onInputChange( { detail } ) {
		this.state.text = detail;

		if ( detail.length < MIN_SEARCH_LENGTH ) {
			this.refs.input.updateSuggestion();
			this.refs.input.updateNbResults();
			return;
		}

		this.trigger( 'search:change', { detail }, false );
	}

	onInputSubmit( { detail } ) {
		this.state.text = detail;
		// this.refs.filters.reset();
		// this.state.filters = this.refs.filters.updateActiveOptions( this.state.text );

		this.debouncedSubmit();
	}

	onFilterChange( { detail } ) {
		this.state.filters[ detail.id ] = detail.selectedOptions;
		this.debouncedSubmit();
	}

	submit() {
		this.trigger( 'search:submit', { detail: this.state }, false );
	}

	// #######################
	// #region PUBLIC METHODS
	// #######################

	updateInputSuggestions( items = [] ) {
		this.refs.input.updateSuggestion( items );
	}

	updateInputNbResults( nb = 0 ) {
		if ( this.refs.input ) {
			this.refs.input.updateNbResults( nb );
		}
	}

	/**
	 * Update options for a given filter
	 *
	 * @param {Array} filtersData Array of objects with filter key & options props
	 */
	updateFilters( filtersData = [] ) {
		filtersData.forEach( ( { key, options } ) => {
			this.refs.filters.updateOptions( key, options );
		} );
	}

	// #######################
	// #endregion
	// #######################
}
