import { debounce } from 'lodash-es';
import Stickyfill from 'stickyfilljs';
import BaseView from '../../../js/base-view';

const CLASS_SUBMENU_ACTIVE = 'profile-nav--submenu';

const DEBOUNCE_DELAY = 10; // ms

export default class ProfileNav extends BaseView {
	bind() {
		this.state = {
			submenuActive: false,
		};

		this.profileNavOffsetTop = this.element.offsetTop;
		Stickyfill.add( this.element );
		this.handleViewportResize();
		this.handleProfileNav();
		this.bindEventListeners();
	}

	bindEventListeners() {
		// viewport
		viewport_service.on( 'change', () => this.handleViewportResize() );

		// page scroll / viewport scroll to update active submenu
		this.onScrollWindow = debounce( this.handleProfileNav.bind( this ), DEBOUNCE_DELAY );
		window.addEventListener( 'scroll', this.onScrollWindow, { passive: true } );
	}

	/* VIEWPORT */
	/**
	 * When screen size changes
	 */
	handleViewportResize() {
		if ( this.state.submenuActive ) {
			return;
		}
		this.profileNavOffsetTop = this.element.offsetTop;
	}

	/* SCROLL */
	/**
	 * Update profile nav
	 */
	handleProfileNav() {
		if ( this.profileNavOffsetTop - window.scrollY <= 0 ) {
			this.element.classList.add( CLASS_SUBMENU_ACTIVE );
			this.state.submenuActive = true;
		}
		else {
			this.element.classList.remove( CLASS_SUBMENU_ACTIVE );
			this.state.submenuActive = false;
		}
	}

	destroy() {
		super.destroy();
		// page scroll
		window.removeEventListener( 'scroll', this.onScrollWindow, { passive: true } );
	}
}
