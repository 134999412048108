import BaseView from '../../../js/base-view';

export default class FilterSelect extends BaseView {
	init() {
		// Bindings
		this.toggleDropdown = this.toggleDropdown.bind( this );
		this.handleSubmit = this.handleSubmit.bind( this );
		this.handleReset = this.handleReset.bind( this );
		this.handleKeyboard = this.handleKeyboard.bind( this );
		this.handleClickOutside = this.handleClickOutside.bind( this );
		this.handleOptionsSelection = this.handleOptionsSelection.bind( this );

		// Elements
		this.refs = {
			button: this.getScopedElement( '.filter-select__button' ),
			buttonText: this.getScopedElement( '.filter-select__button-text' ),
			listbox: this.getScopedElement( '.filter-select__listbox' ),
			optionsList: this.getScopedElement( '.filter-select__options' ),
			options: Array.from( this.getScopedElements( '.filter-select__option' ) ),
			btnSubmit: this.getScopedElement( '.filter-select__btn-submit button' ),
			btnReset: this.getScopedElement( '.filter-select__btn-reset' ),
		};

		const selectedOptions = this.refs.options
			.filter( ( option ) => option.getAttribute( 'aria-selected' ) === 'true' )
			.map( ( o ) => o.dataset.value );

		// State
		this.state = {
			isOpen: false,
			selectedOptions: new Set( selectedOptions ),
		};

		this.previousState = null;

		// Track focusable elements
		this.focusableElements = [
			...this.refs.options,
			this.refs.btnSubmit,
			this.refs.btnReset,
		];

		// Add search state
		this.searchTerm = '';
		this.searchTimeout = null;

		this.bindEvents();
		this.updateButtonText();

		// Return the instance for chaining
		return this;
	}

	bindEvents() {
		// Toggle dropdown
		this.refs.button.addEventListener( 'click', this.toggleDropdown );

		// Actions
		this.refs.btnSubmit.addEventListener( 'click', this.handleSubmit );
		this.refs.btnReset.addEventListener( 'click', this.handleReset );

		// Handle option selection
		this.refs.listbox.addEventListener( 'click', this.handleOptionsSelection );

		// Close dropdown when clicking outside
		document.addEventListener( 'click', this.handleClickOutside );

		// Keyboard navigation
		this.refs.listbox.addEventListener( 'keydown', this.handleKeyboard );
	}

	handleOptionsSelection( e ) {
		const option = e.target.closest( '[role="option"]' );
		if ( option ) {
			this.toggleOption( option );
		}
	}

	toggleDropdown() {
		this.state.isOpen = ! this.state.isOpen;
		this.refs.button.setAttribute( 'aria-expanded', String( this.state.isOpen ) );

		if ( this.state.isOpen ) {
			this.previousState = {
				selectedOptions: new Set( this.state.selectedOptions ),
				checkedOptions: this.refs.options.map( ( option ) => ( {
					element: option,
					checked: option.querySelector( '.checkbox input' ).checked,
					selected: option.getAttribute( 'aria-selected' ) === 'true',
				} ) ),
			};
		}

		this.element.classList.toggle( '-is-open', this.state.isOpen );
		this.updateButtonText();
		this.updateTabIndexes();

		if ( this.state.isOpen ) {
			this.refs.options[ 0 ].focus();
		}
	}

	closeDropdown() {
		this.state.isOpen = false;
		this.refs.button.setAttribute( 'aria-expanded', 'false' );
		this.element.classList.remove( '-is-open' );

		this.updateButtonText();
		this.updateTabIndexes();

		this.refs.button.focus();
	}

	toggleOption( option ) {
		const isSelected = option.getAttribute( 'aria-selected' ) === 'true';
		option.setAttribute( 'aria-selected', String( ! isSelected ) );

		const checkbox = option.querySelector( '.checkbox input' );
		checkbox.checked = ! checkbox.checked;

		this.state.selectedOptions[ isSelected ? 'delete' : 'add' ]( option.dataset.value );
	}

	updateButtonText() {
		if ( this.state.isOpen ) {
			this.refs.buttonText.textContent = this.refs.button.dataset.placeholder;
		}
		else {
			// eslint-disable-next-line no-lonely-if
			if ( this.state.selectedOptions.size === 0 ) {
				this.element.classList.remove( '-has-value' );
				this.refs.buttonText.textContent = this.refs.button.dataset.label;
			}
			else {
				this.element.classList.add( '-has-value' );
				this.refs.buttonText.textContent = `${ this.refs.button.dataset.label } (${ this.state.selectedOptions.size })`;
			}
		}
	}

	updateTabIndexes() {
		this.refs.options.forEach( ( option ) => {
			option.setAttribute( 'tabindex', this.state.isOpen ? '0' : '-1' );
		} );

		this.refs.btnSubmit.setAttribute(
			'tabindex',
			this.state.isOpen ? '0' : '-1'
		);
		this.refs.btnReset.setAttribute( 'tabindex', this.state.isOpen ? '0' : '-1' );
	}

	handleSubmit() {
		// Close dropdown
		this.closeDropdown();
		this.trigger( 'filter:change', {
			detail: {
				id: this.element.id,
				selectedOptions: Array.from( this.state.selectedOptions ).sort(),
			},
		}, false );
	}

	handleReset() {
		// Reset options
		this.refs.options.forEach( ( option ) => {
			option.setAttribute( 'aria-selected', 'false' );
			const checkbox = option.querySelector( '.checkbox input' );
			checkbox.checked = false;
		} );

		// Reset selected options
		this.state.selectedOptions.clear();

		this.trigger( 'filter:change', {
			detail: {
				id: this.element.id,
				selectedOptions: [],
			},
		}, false );

		this.updateButtonText();

		this.closeDropdown();
	}

	/**
	 * On click outside, reset the filter and close the dropdown
	 * It validates only if we click on the "Validate" button
	 *
	 * @param {Event} e - The click event
	 */
	handleClickOutside( e ) {
		if ( this.state.isOpen && ! this.element.contains( e.target ) ) {
			this.cancel();
		}
	}

	cancel() {
		if ( ! this.previousState ) {
			this.closeDropdown();
			return;
		}

		// Restore selected options
		this.state.selectedOptions = new Set( this.previousState.selectedOptions );

		// Restore checkbox states and aria-selected
		this.previousState.checkedOptions.forEach(
			( { element, checked, selected } ) => {
				// eslint-disable-next-line no-param-reassign
				element.querySelector( '.checkbox input' ).checked = checked;
				element.setAttribute( 'aria-selected', String( selected ) );
			}
		);

		// Reset previous state
		this.previousState = null;

		this.closeDropdown();
	}

	handleKeyboard( e ) {
		if ( ! this.state.isOpen ) {
			return;
		}

		const currentIndex = this.refs.options.findIndex(
			( opt ) => opt === document.activeElement
		);

		switch ( e.code ) {
			case 'ArrowDown':
				e.preventDefault();
				// eslint-disable-next-line no-case-declarations
				const nextIndex =
					currentIndex < this.refs.options.length - 1 ? currentIndex + 1 : 0;
				this.refs.options[ nextIndex ].focus();
				break;

			case 'ArrowUp':
				e.preventDefault();
				// eslint-disable-next-line no-case-declarations
				const prevIndex =
					currentIndex > 0 ? currentIndex - 1 : this.refs.options.length - 1;
				this.refs.options[ prevIndex ].focus();
				break;

			case 'Space':
				if ( document.activeElement.getAttribute( 'role' ) === 'option' ) {
					e.preventDefault();
					this.toggleOption( document.activeElement );
				}
				break;

			case 'Enter':
				e.preventDefault();
				this.handleSubmit();
				break;

			case 'Tab':
				// eslint-disable-next-line no-case-declarations
				const currentFocusIndex = this.focusableElements.indexOf(
					document.activeElement
				);

				if (
					! e.shiftKey &&
					currentFocusIndex === this.focusableElements.length - 1
				) {
					e.preventDefault();
					// Last element, going forward - focus the first element
					this.focusableElements[ 0 ].focus();
				}
				// If we're on an option, move focus to the appropriate button
				else if ( document.activeElement.getAttribute( 'role' ) === 'option' ) {
					e.preventDefault();
					if ( e.shiftKey ) {
						// Going backward - focus the reset button
						this.refs.btnReset.focus();
					}
					else {
						// Going forward - focus the submit button
						this.refs.btnSubmit.focus();
					}
				}
				break;

			case 'Escape':
				this.cancel();
				break;

			default:
				// Only handle printable characters
				if ( e.key.length === 1 && ! e.ctrlKey && ! e.altKey && ! e.metaKey ) {
					this.handleSearch( e.key );
				}
				break;
		}
	}

	handleSearch( char ) {
		// Add the character to search term
		this.searchTerm += char.toLowerCase();

		// Clear previous timeout
		if ( this.searchTimeout ) {
			clearTimeout( this.searchTimeout );
		}

		// Reset search term after 500ms of no input
		this.searchTimeout = setTimeout( () => {
			this.searchTerm = '';
		}, 500 );

		// Find the first option that starts with the search term
		const matchingOption = this.refs.options.find( ( option ) => {
			const label = option.querySelector(
				'.filter-select__option-label-text'
			).textContent;
			return label.trim().toLowerCase().startsWith( this.searchTerm );
		} );

		// Focus the matching option if found
		if ( matchingOption ) {
			matchingOption.focus();
		}
	}

	updateOptions( newOptions = [] ) {
		// Clear existing options
		this.refs.optionsList.innerHTML = '';

		const newSelectedOptions = new Set();

		// Create and append new options
		newOptions.forEach( ( o, i ) => {
			const item = `
				<li
					class="filter-select__option"
					role="option"
					aria-selected="${ o.checked ? 'true' : 'false' }"
					id="${ o?.id ?? `option-${ i }` }"
					data-value="${ o.value }"
					tabindex="-1"
				>
					<span class="filter-select__option-inner">
						<span class="checkbox">
							<input type="checkbox" tabindex="-1"${ o.checked ? '	checked' : '' } />
							<span class="checkbox__icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
									<path d="M10.5497 4.4625L5.97487 9.03737L3.5 6.5625" stroke="currentColor" stroke-width="1.5"/>
								</svg>
							</span>
						</span>
						<span class="filter-select__option-label">
							<span class="filter-select__option-label-text">
								${ o.label }
							</span>
						</span>
					</span>
				</li>
			`;

			if ( o.checked ) {
				newSelectedOptions.add( o.value );
			}

			this.refs.optionsList.insertAdjacentHTML( 'beforeend', item );
		} );

		// Update the selectedOptions state
		this.state.selectedOptions = newSelectedOptions;

		this.updateButtonText();
		this.updateTabIndexes();

		this.trigger( 'filter:change', {
			detail: {
				id: this.element.id,
				selectedOptions: Array.from( newSelectedOptions ).sort(),
			},
		}, false );
	}
}
