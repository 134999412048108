/* eslint-disable no-underscore-dangle */
import { isEmpty } from 'lodash-es';

import BaseArchiveModel from './page-archive--model';

export default class PageArchiveProjects extends BaseArchiveModel {
	initialize() {
		super.initialize();

		this.statusesItems = this.getFilterItems( 'statuses' );
		this.thematicsItems = this.getFilterItems( 'thematics' );
	}

	getInitialState() {
		const queryArgs = new URLSearchParams( window.location.search );

		return {
			...super.getInitialState(),
			search: queryArgs.get( 'q' ) ?? '',
		};
	}

	getAggs() {
		return {
			categories: {
				global: {},
				aggs: {
					wordpress: {
						nested: {
							path: 'wordpress',
						},
						aggs: {
							unique: {
								terms: {
									field: 'wordpress.category',
									size: 1000,
									order: { _key: 'asc' },
								},
							},
						},
					},
					root: {
						terms: {
							field: 'cat',
							size: 1000,
							order: { _key: 'asc' },
						},
					},
				},
			},
			authors: {
				global: {},
				aggs: {
					wordpress: {
						nested: {
							path: 'wordpress',
						},
						aggs: {
							unique: {
								terms: {
									field: 'wordpress.authors',
									size: 1000,
									order: { _key: 'asc' },
								},
							},
						},
					},
					root: {
						terms: {
							field: 'author_ids',
							size: 1000,
							order: { _key: 'asc' },
						},
					},
				},
			},
			dates: {
				global: {},
				aggs: {
					wordpress: {
						nested: {
							path: 'wordpress',
						},
						aggs: {
							unique: {
								date_histogram: {
									field: 'wordpress.date',
									calendar_interval: 'month',
									format: 'yyyy-MM',
									order: { _key: 'desc' },
								},
							},
						},
					},
					root: {
						date_histogram: {
							field: 'date',
							calendar_interval: 'month',
							format: 'yyyy-MM',
							order: { _key: 'desc' },
						},
					},
				},
			},
		};
	}

	getQuery() {
		// Empty
		const queryArgs = [];

		// search text
		if ( ! isEmpty( this.state.search ) ) {
			// Escape special characters in the search query
			const sanitizedSearch = this.state.search.replace(
				// eslint-disable-next-line no-useless-escape
				/[/:!^$?.*(){}&"<>#@+|~\[\]]/g,
				'\\$&',
			);

			queryArgs.push( {
				match: {
					'title.fr': {
						query: sanitizedSearch,
						fuzziness: 1,
					},
				},
			}	);
		}

		// Filters selected
		if ( ! isEmpty( this.state.filters.statuses ) ) {
			queryArgs.push( {
				terms: {
					status: this.state.filters.statuses.map( ( v ) => this.statusesItems[ v ] ),
				},
			} );
		}
		if ( ! isEmpty( this.state.filters.thematics ) ) {
			queryArgs.push( {
				terms: {
					'thematics.fr': this.state.filters.thematics.map( ( v ) => this.thematicsItems[ v ] ),
				},
			} );
		}

		return {
			bool: {
				must: queryArgs,
			},
		};
	}

	getSort() {
		const sort = [
			{ start: 'desc' },
			{ end: 'asc' },
		];

		if ( ! isEmpty( this.state.search ) ) {
			sort.unshift( '_score' );
		}

		return sort;
	}

	async prepareHits( hits ) {
		if ( hits.length === 0 ) {
			return [];
		}

		const profiles = await this.getProfiles(
			hits.reduce( ( acc, { _source: s } ) => {
				( s?.wordpress?.members ?? s?.members ?? [] ).forEach( ( { id } ) => {
					if ( !! id && ! acc.includes( id ) ) {
						acc.push( id );
					}
				} );

				return acc;
			}, [] ),
		);

		const finalHits = hits.map( ( hit ) => ( {
			...hit,
			_source: {
				...hit._source,
				title: hit._source?.wordpress?.title ?? hit._source?.title?.fr ?? '',
				members: ( hit._source?.wordpress?.members ?? hit._source?.members ?? [] )
					.map( ( m ) => profiles?.[ m.id ] ?? m ),
			},
		} ) );

		return finalHits;
	}

	getCardContext( { _source: s } ) {
		const isPhd = s.status.includes( 'Doctorat' );
		const isDone = s.status.includes( 'Terminé' );

		const details = [];

		if ( s.thematics.fr.length > 0 ) {
			details.push( {
				title: 'Thématique',
				description: s.thematics.fr.join( ', ' ),
			} );
		}
		if ( s.members.length > 0 ) {
			const sortedMembers = s.members.sort( ( a, b ) => a.order - b.order );
			details.push( {
				title: 'Équipe',
				description: sortedMembers
					.map( ( m ) => {
						let item = `${ m?.name ?? `${ m.lastname.trim() } ${ m.firstname.trim() }` }`.trim();
						if ( m.isCurrentlyActive ) {
							item = `<a href="${ m.link }" target="_blank">${ item }</a>`;
						}

						return item;
					} )
					.join( ', ' ),
			} );
		}

		if ( s.funders?.length > 0 ) {
			details.push( {
				title: 'Fonds',
				description: s.funders.map( ( { fr } ) => fr ).filter( Boolean ).join( ', ' ),
			} );
		}

		return {
			id: s.id,
			modifiers: isDone ? [ 'card-research--done' ] : [],
			data: {
				details,
				isPhd,
				link: {
					href: `${ this.config?.baseUrlProject ?? '/projects' }/${ s.slug }/`,
					title: 'Consulter',
				},
				status: s.status,
				title: s.title,
			},
		};
	}

	renderCard( { modifiers = [], data = {} } ) {
		return `
		<article class="card-research ${ modifiers.join( ' ' ) }">
			<div class="card-research__inner">

				<div class="card-research__header">
					<span class="card-research__icon">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
							${ data.isPhd ? '<path d="M3 7V31H23M29 25V5H22.5M29 25L23 31M29 25H23V31M7 25.5H19M7 20.5H19M6.38095 8.4V14.4L9.11442 15.0143C12.0021 15.6634 14.9979 15.6634 17.8856 15.0143L20.619 14.4V8.4M23.6638 7.13333V12.2667M25 5.73333L13.5 2L2 5.73333V6.8L13.5 10.5333L25 6.8V5.73333Z" stroke="black" stroke-width="2"/>' : '<path d="M20 15H25M20 10H25M16.2658 5H29V25M29 25L23 31M29 25H23V31M23 31H3V12.5M7 25.5H17M7 20.5H17M15.4787 14.0574L21.4213 20M17.1635 8.58175C17.1635 12.769 13.769 16.1635 9.58175 16.1635C5.39446 16.1635 2 12.769 2 8.58175C2 4.39446 5.39446 1 9.58175 1C13.769 1 17.1635 4.39446 17.1635 8.58175Z" stroke="black" stroke-width="2"/>' }
						</svg>
					</span>
					<h2 class="card-research__title">${ data.title }</h2>
				</div>

				<div class="card-research__content">
					<span class="tag tag--big card-research__status">
						<span class="tag__text">${ data.status }</span>
					</span>

					<ul class="card-research__details">
						${ data.details.map( ( d ) => `
							<li class="card-research__detail-item">
								<span class="card-research__detail-title">${ d.title }</span>
								<span class="card-research__detail-desc">${ d.description }</span>
							</li>` ).join( '' ) }
					</ul>
				</div>

				<div class="card-research__link-wrapper">
					<a class="card-research__link" href="${ data.link.href }">
						<span>${ data.link.title }</span>
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
							<path d="M19.5 12.0421L12.5 5M19.5 12.0421L12.5 19M19.5 12.0421L4 12.0421" stroke="currentColor" stroke-width="1.5"/>
						</svg>
					</a>
				</div>
			</div>
		</article>`;
	}
}
