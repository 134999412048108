import { isEmpty, isEqual } from 'lodash-es';

import BaseArchiveModel from './page-archive--model';
import requester from '../../../js/utils/requester';
import sanitize from '../../../js/utils/sanitize';

export default class PageArchiveEvents extends BaseArchiveModel {
	initialize() {
		super.initialize();

		this.monthsItems = this.getFilterItems( 'months', ( o ) => o.id );
	}

	handleSearchChange() {
		return false;
	}

	handleFiltersChange( newFilters ) {
		const newState = {
			...this.state.filters,
			...newFilters,
		};

		if ( isEmpty( this.state.filters.archive ) && ! isEmpty( newFilters.archive ) ) {
			newState.categories = [];
			newState.months = [];
		}

		if ( ! isEqual( this.state.filters, newState ) ) {
			this.state.filters = newState;
			return true;
		}

		return false;
	}

	getAggs() {
		return {
			categories: {
				nested: { path: 'categories' },
				aggs: {
					unique: {
						terms: { field: 'categories.slug', size: 1000, order: { _key: 'asc' } },
						aggs: { title: { terms: { field: 'categories.title', size: 1 } } },
					},
				},
			},
			months: {
				date_histogram: {
					field: 'start_date',
					calendar_interval: 'month',
					order: { _key: isEmpty( this.state.filters.archive ) ? 'asc' : 'desc' },
					min_doc_count: 1,
				},
			},
		};
	}

	getResultsQuery() {
		const queryArgs = [];

		// Filters selected
		if ( ! isEmpty( this.state.filters ) ) {
			if ( ! isEmpty( this.state.filters.categories ) ) {
				queryArgs.push( {
					nested: {
						path: 'categories',
						query: {
							bool: {
								must: [
									{
										terms: {
											'categories.slug': this.state.filters.categories,
										},
									},
								],
							},
						},
					},
				} );
			}

			if ( isEmpty( this.state.filters.months ) && isEmpty( this.state.filters.archive ) ) {
				// Only show events from today onwards
				queryArgs.push( {
					range: {
						start_date: {
							gte: 'now/d', // From today (rounded to start of day)
						},
					},
				} );
			}

			if ( ! isEmpty( this.state.filters.months ) ) {
				queryArgs.push( {
					bool: {
						should: this.state.filters.months.map( ( v ) => {
							const monthDate = this.monthsItems[ v ];
							return {
								range: {
									start_date: {
										gte: monthDate,
										lt: `${ monthDate }||+1M`,
									},
								},
							};
						} ),
					},
				} );
			}

			if ( ! isEmpty( this.state.filters.archive ) ) {
				queryArgs.push( {
					bool: {
						must: [
							{
								range: {
									start_date: {
										lt: 'now/d',
									},
								},
							},
							{
								range: {
									start_date: {
										gte: `${ this.state.filters.archive }-01-01`,
										lte: `${ this.state.filters.archive }-12-31`,
									},
								},
							},
						],
					},
				} );
			}
		}

		return {
			bool: {
				must: queryArgs,
			},
		};
	}

	getAggsQuery() {
		const queryArgs = [
			{
				range: {
					start_date: {
						[ isEmpty( this.state.filters.archive ) ? 'gte' : 'lt' ]: 'now/d',
					},
				},
			},
		];

		if ( ! isEmpty( this.state.filters.archive ) ) {
			queryArgs.push( {
				range: {
					start_date: {
						gte: `${ this.state.filters.archive }-01-01`,
						lte: `${ this.state.filters.archive }-12-31`,
					},
				},
			} );
		}

		return {
			bool: {
				must: queryArgs,
			},
		};
	}

	getSort() {
		const dateOrderBy = isEmpty( this.state.filters.archive ) ? 'asc' : 'desc';

		const sort = [
			{
				start_date: {
					order: dateOrderBy,
					missing: '_last',
				},
			},
		];

		if ( ! isEmpty( this.state.search ) ) {
			sort.unshift( '_score' );
		}

		return sort;
	}

	query() {
		const fromIndex = this.config.nbPerPage * ( this.state.page - 1 );
		const resultsQuery = this.getResultsQuery();
		const args = {
			name: 'pageArchive:query',
			url: `${ this.config.elasticHost }/${ this.config.index }/_msearch`,
			method: 'post',
			headers: {
				'Content-Type': 'application/x-ndjson',
			},
			data: [
				{},
				// Query for results
				{
					from: fromIndex,
					query: resultsQuery,
					size: this.config.nbPerPage,
					sort: this.getSort(),
				},
				// Query for aggregations
				{ index: this.config.index },
				{
					aggs: this.getAggs(),
					from: 0,
					size: 0,
					query: this.getAggsQuery(),
				},
			],
		};

		if ( ! isEmpty( this.config.auth ) ) {
			args.auth = {
				username: this.config.auth.u,
				password: this.config.auth.p,
			};
		}

		requester( args )
			.then( async ( { data } ) => {
				const resResults = data.responses[ 0 ];
				const hits = await this.prepareHits( resResults.hits.hits );
				const results = hits.map( this.getCardContext.bind( this ) );
				const hasMoreItemsToLoad = ( fromIndex + results.length < resResults.hits.total.value );
				this.refs.resultList[ ( this.state.page > 1 ? 'addPage' : 'setList' ) ]( results, ! hasMoreItemsToLoad );

				this.refs.searchBar.updateInputNbResults(
					( resultsQuery?.bool?.must?.length ?? 0 ) > 0
						? resResults.hits.total.value
						: 0
				);

				const { categories = {}, months = {} } = data.responses[ 1 ]?.aggregations ?? {};

				this.refs.searchBar.updateFilters( [
					{
						key: 'categories',
						options: categories.unique.buckets
							.map( ( b ) => ( {
								value: b.key,
								label: b.title.buckets[ 0 ].key,
								checked: this.state.filters.categories.includes( b.key ),
							} ) ),
					},
					{
						key: 'months',
						options: this.dateBucketsToOptions( months.buckets, this.state.filters.months ),
					},
				] );

				this.monthsItems = this.getItems( 'months', ( o ) => o.id );
			} );
	}

	dateBucketsToOptions( buckets, selectedTerms = [] ) {
		// Create options for date formatting
		const dateFormatter = new Intl.DateTimeFormat( 'fr-CH', {
			year: 'numeric',
			month: 'long',
		} );

		const options = [];

		buckets.forEach( ( item ) => {
			if ( item.key === 0 ) {
				return;
			}

			const date = new Date( item.key_as_string );
			const label = dateFormatter.format( date );
			const value = sanitize( label );

			const key = date.toISOString().split( 'T' )[ 0 ]; // YYYY-MM-DD format

			options.push( {
				value,
				label: label.charAt( 0 ).toUpperCase() + label.slice( 1 ), // ucfirst equivalent
				checked: selectedTerms.includes( value ),
				id: key,
			} );
		} );

		return options;
	}

	getCardContext( { _source: source } ) {
		const primaryCategory = source.categories.find( ( c ) => c.primary ) ?? source.categories[ 0 ];

		return {
			modifiers: [],
			data: {
				header: {
					title: 'Événement',
					icon: 'event',
					tag: primaryCategory ? {
						text: primaryCategory.title,
						link: primaryCategory.url,
					} : null,
				},
				title: source.title,
				image: source.image,
				link: source.url,
				dates: {
					start: new Date( source.start_date ),
					end: source.end_date ? new Date( source.end_date ) : null,
				},
				hours: source.hours,
				location: source.location,
				button: {
					title: 'Plus d\'informations',
				},
			},
		};
	}

	renderCard( { modifiers = [], data = {} } ) {
		const tag = `<span class="tag">
			${ data?.header?.tag
			? `<a href="${ data.header.tag.link }" class="tag__text" rel="nofollow">${ data.header.tag.text }</a>`
			: `<span class="tag__text">${ data.header.title }</span>` }
		</span>`;

		const intlShort = new Intl.DateTimeFormat( 'fr-CH', { day: '2-digit', month: '2-digit' } );
		const intlFull = new Intl.DateTimeFormat( 'fr-CH', { day: '2-digit', month: '2-digit', year: 'numeric' } );

		return `
		<article class="card-article card-article--event ${ modifiers.join( ' ' ) }">
			<div class="card-article__inner">
				<div class="card-article__header">
					${ tag }
					<div class="card-article__image">
						${ data?.image?.picture ? `<picture>${ data.image?.picture }</picture>` : '' }
					</div>
					<div class="card-article__header_icon">
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" role="img">
						<title>{{ data.header.title }</title>
						<path d="M21.75 17.25V4.5H2.25V21.75H17.25M21.75 17.25L17.25 21.75M21.75 17.25H17.25V21.75M2.25 9.75H21.75M7.5 2.25V6.75M16.5 2.25V6.75" stroke="currentColor" stroke-width="1.5"/>
					</svg>
					</div>
				</div>
				<div class="card-article__content">
					<div class="card-article__text">
						<time class="card-article__date" datetime="${ data.dates.start.toISOString() }">
							${ data.dates.end ? `${ intlShort.format( data.dates.start ) } - ${ intlFull.format( data.dates.end ) }` : intlFull.format( data.dates.start ) }
						</time>
						<h2 class="card-article__title">${ data.title }</h2>
						<div class="card-article__meta">
							${ data.hours ? `
								<span class="card-article__meta_item">
									<svg role="img" class="card-article__meta_item_icon" width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<title>Horaire</title>
										<path d="m15.473 16.527 1.054-1.054-3.777-3.777V7h-1.5v5.304l4.223 4.223ZM12.002 21.5a9.254 9.254 0 0 1-3.706-.748 9.596 9.596 0 0 1-3.016-2.03 9.595 9.595 0 0 1-2.032-3.016 9.246 9.246 0 0 1-.748-3.704c0-1.314.25-2.55.748-3.706a9.596 9.596 0 0 1 2.03-3.016 9.594 9.594 0 0 1 3.016-2.032 9.246 9.246 0 0 1 3.704-.748c1.314 0 2.55.25 3.706.748a9.596 9.596 0 0 1 3.017 2.03 9.594 9.594 0 0 1 2.03 3.016 9.247 9.247 0 0 1 .749 3.704c0 1.314-.25 2.55-.748 3.706a9.596 9.596 0 0 1-2.03 3.017 9.595 9.595 0 0 1-3.016 2.03 9.247 9.247 0 0 1-3.704.749ZM12 20c2.217 0 4.104-.78 5.663-2.337C19.22 16.104 20 14.217 20 12s-.78-4.104-2.337-5.662C16.104 4.779 14.217 4 12 4s-4.104.78-5.662 2.338C4.779 7.896 4 9.783 4 12s.78 4.104 2.338 5.663C7.896 19.22 9.783 20 12 20Z" fill="currentColor"/>
									</svg>
									<span class="card-article__meta_item_text">${ data.hours }</span>
								</span>` : '' }
							${ data.location ? `
								<span class="card-article__meta_item">
									<svg role="img" class="card-article__meta_item_icon" width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<title>Lieu</title>
										<path d="M12.002 11.865c.497 0 .922-.177 1.276-.531.353-.355.53-.78.53-1.278 0-.497-.178-.923-.532-1.276a1.746 1.746 0 0 0-1.278-.53c-.497 0-.923.177-1.276.531-.353.355-.53.78-.53 1.278s.177.923.532 1.277c.354.353.78.53 1.278.53ZM12 19.514c1.956-1.752 3.453-3.432 4.491-5.04 1.038-1.608 1.557-3.017 1.557-4.226 0-1.823-.58-3.322-1.738-4.496-1.158-1.174-2.595-1.761-4.31-1.761-1.715 0-3.152.587-4.31 1.761-1.159 1.174-1.738 2.673-1.738 4.496 0 1.209.519 2.618 1.557 4.226 1.037 1.608 2.535 3.288 4.49 5.04Zm0 1.995c-2.517-2.18-4.404-4.21-5.662-6.088-1.257-1.878-1.886-3.602-1.886-5.173 0-2.308.746-4.176 2.24-5.605C8.183 3.214 9.953 2.5 12 2.5s3.816.714 5.309 2.143c1.492 1.43 2.239 3.297 2.239 5.605 0 1.57-.629 3.295-1.887 5.173C16.404 17.3 14.517 19.33 12 21.51Z" fill="currentColor"/>
									</svg>
									<span class="card-article__meta_item_text">${ data.location }</span>
								</span>` : '' }
						</div>
					</div>
					${ data.button ? `
						<div class="wp-block-button is-style-secondary">
							<a
								class="wp-block-button__link"
								href="${ data.link }"
							>
								${ data.button.title }
							</a>
						</div>` : '' }
				</div>
			</div>
		</article>`;
	}
}
