import BaseView from '../../../js/base-view';
import FilterSelect from '../filter-select/filter-select';
import FilterSelectSingle from '../filter-select/filter-select--single';

const SELECTOR_FILTER_ITEM = '[data-filter-select]';

export default class Filters extends BaseView {
	init() {
		const selects = this.element
			? [ ...this.element.querySelectorAll( SELECTOR_FILTER_ITEM ) ]
			: [];

		this.refs = {
			selects: selects.reduce( ( acc, select ) => ( {
				...acc,
				[ select.id ]: select.classList.contains( '-single' )
					? new FilterSelectSingle( select ).init()
					: new FilterSelect( select ).init(),
			} ), {} ),
		};

		return this;
	}

	/**
	 * Update the options for a given filter
	 *
	 * @param {string} key Filter id name
	 * @param {Array} options Array of options
	 */
	updateOptions( key, options = null ) {
		const filter = this.refs.selects?.[ key ];
		if ( filter ) {
			filter.updateOptions( options );
		}
	}
}
