import BaseView from '../../../js/base-view';

const FIELD_SELECTOR = '.input__field';
const FILE_ITEMS_SELECTOR = '.input__items';
const FIELD_ERROR_SELECTOR = '[data-error-text]';

const FILLED_CLASS = 'is-filled';
const HIDDEN_CLASS = 'is-hidden';
const MAX_CLASS = 'has-max-items';

/**
 * @class InputFile
 */
export default class InputFile extends BaseView {
	init() {
		this.hasFile = false;

		this.field = this.getScopedElement( FIELD_SELECTOR );
		this.fieldError = this.getScopedElement( FIELD_ERROR_SELECTOR );
		this.itemsWrapper = this.getScopedElement( FILE_ITEMS_SELECTOR );
		this.items = [];

		this.files = [];

		this.fieldAttrs = this.field.attributes;

		this.max = this.element.dataset?.max ?? 1;

		this.bind();
		this.toggleMaxFiles();
	}

	bind() {
		this.deleteFile = this.deleteFile.bind( this );
		this.onFileChange = this.onFileChange.bind( this );

		this.on( 'change', FIELD_SELECTOR, this.onFileChange );
	}

	onFileChange() {
		if ( this.field.files.length === 0 ) {
			return;
		}

		this.resetError();

		this.populateFileItems( this.field.files );

		this.setIsFilled();

		this.toggleMaxFiles();
	}

	populateFileItems( files ) {
		// Create a new DataTransfer to store all files
		const dataTransfer = new DataTransfer();
		this.files.forEach( ( file ) => {
			dataTransfer.items.add( file );
		} );

		Array.from( files ).forEach( ( file ) => {
			this.files.push( file );
			dataTransfer.items.add( file );

			const item = document.createElement( 'li' );
			item.classList.add( 'input__item' );

			const deleteButton = document.createElement( 'button' );
			deleteButton.type = 'button';
			deleteButton.classList.add( 'input__item-delete' );

			item.innerHTML = `
				<span class="input__item-name">${ file.name }</span>
			`;

			item.appendChild( deleteButton );
			this.itemsWrapper.appendChild( item );

			this.items.push( {
				item,
				file,
				deleteButton,
			} );

			deleteButton.addEventListener( 'click', this.deleteFile );
		} );

		// Update input files
		this.field.files = dataTransfer.files;

		this.hasFile = true;
		this.trigger( 'selected', null, false );
	}

	deleteFile( event ) {
		const itemToDelete = this.items.find( ( item ) => item.deleteButton === event.target );

		// Remove event listener
		itemToDelete.deleteButton.removeEventListener( 'click', this.deleteFile );

		// Destroy field
		this.itemsWrapper.removeChild( itemToDelete.item );

		// Remove item from items array
		this.items = this.items.filter( ( item ) => item !== itemToDelete );

		// Remove file from files array
		this.files = this.files.filter( ( file ) => file !== itemToDelete.file );

		// Update input files
		const dataTransfer = new DataTransfer();
		this.files.forEach( ( file ) => {
			dataTransfer.items.add( file );
		} );
		this.field.files = dataTransfer.files;

		// trigger event for possible listeners
		this.trigger( 'deleted', null, false );

		this.setIsFilled();
		this.toggleMaxFiles();
	}

	deleteFiles() {
		this.items.forEach( ( item ) => {
			this.deleteFile( { target: item.deleteButton } );
		} );
	}

	setError( errors ) {
		this.element.setAttribute( 'data-error', 'true' );

		if ( ! Array.isArray( errors ) ) {
			// eslint-disable-next-line no-param-reassign
			errors = [ errors ];
		}

		this.fieldError.innerHTML = errors.map( ( e ) => `- fichier ${ e.idx + 1 }: ${ e.error }` ).join( '<br>' );
	}

	resetError() {
		this.element.removeAttribute( 'data-error' );
		this.fieldError.innerHTML = '';
	}

	reset() {
		this.resetError();
		this.deleteFiles();
		this.setIsFilled();
	}

	/**
	 * Set the state of is_filled and it's class to the element
	 */
	setIsFilled() {
		if ( this.files.length === 0 ) {
			this.element.classList.remove( FILLED_CLASS );
		}
		else {
			this.element.classList.add( FILLED_CLASS );
		}
	}

	toggleMaxFiles() {
		const hasMaxFiles = this.files.length >= this.max;
		this.element.classList[ hasMaxFiles ? 'add' : 'remove' ]( MAX_CLASS );
	}

	focus() {
		this.field.focus();
	}

	getName() {
		return this.field.name.replace( '[]', '' );
	}

	show() {
		this.element.classList.remove( HIDDEN_CLASS );
	}

	hide() {
		this.element.classList.add( HIDDEN_CLASS );
	}
}
