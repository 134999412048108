const TIME_BEFORE_TRANSITION = 1.2;
const TIME_BEFORE_VIDEO_APPEARS = 0.7;

const VIDEO_SELECTOR = 'video';
const TOGGLE_PLAY_SELECTOR = '.header__toggle-play';
const TOGGLE_PLAY_ICON_SELECTOR = '.header__toggle-play svg';

const FADE_OUT_CLASS = 'is-fade-out';

export default class HeaderVideo {
	constructor( videoWrapEl ) {
		if ( ! ( window.supt && window.supt.header && window.supt.header.playlist ) ) {
			return false;
		}

		this.el = videoWrapEl;

		this.refs = {
			videos: Array.from( this.el.querySelectorAll( VIDEO_SELECTOR ) ),
			toggleVideoBtn: this.el.querySelector( TOGGLE_PLAY_SELECTOR ),
			toggleVideoIcon: this.el.querySelector( TOGGLE_PLAY_ICON_SELECTOR ),
		};

		this.props = {
			playlist: window.supt.header.playlist,
			texts: {
				pause: this.refs.toggleVideoBtn.getAttribute( 'title' ),
				play: this.refs.toggleVideoBtn.getAttribute( 'data-play-text' ),
			},
		};

		this.state = {
			isPlaying: false,

			current: 0,
			next: 1,
			currentEl: this.refs.videos[ 0 ],
			nextEl: this.refs.videos[ 1 ],
		};

		this.bind();
	}

	bind() {
		this.videoEndedHandler = this.videoEnded.bind( this );
		this.videoTimeUpdateHandler = this.videoTimeUpdate.bind( this );
		this.togglePlayVideoHandler = this.togglePlayVideo.bind( this );

		this.refs.videos.forEach( ( video ) => {
			video.addEventListener( 'ended', this.videoEndedHandler );
			video.addEventListener( 'timeupdate', this.videoTimeUpdateHandler );
		} );
		this.refs.toggleVideoBtn.addEventListener( 'click', this.togglePlayVideoHandler );
	}

	coverVideoPolyfill( videoEl ) {
		const blockRatio = this.el.clientHeight / this.el.clientWidth;
		const videoRatio = videoEl.videoHeight / videoEl.videoWidth;
		let scaleRatio = 1;

		if ( blockRatio > videoRatio ) {
			const videoRealHeight = this.el.clientWidth * videoRatio;
			const diff = this.el.clientHeight - videoRealHeight;
			scaleRatio += diff / videoRealHeight;
		}
		else {
			const videoRealWidth = this.el.clientHeight *
				( videoEl.videoWidth / videoEl.videoHeight );
			const diff = this.el.clientWidth - videoRealWidth;

			scaleRatio += diff / videoRealWidth;
		}

		// eslint-disable-next-line no-param-reassign
		videoEl.style.transform = `scale(${ scaleRatio })`;
	}

	videoEnded() {
		this.setNextVideo();
	}

	videoTimeUpdate() {
		if ( this.state.currentEl.duration - this.state.currentEl.currentTime < TIME_BEFORE_TRANSITION ) {
			this.switchVideos();
		}
	}

	start() {
		if ( typeof this.el === 'undefined' ) {
			return false;
		}

		this.play();
		this.setNextVideo();

		return true;
	}

	play() {
		this.state.currentEl.play();
		this.refs.toggleVideoIcon.setAttribute( 'xlink:href', '#pause' );
		this.refs.toggleVideoBtn.setAttribute( 'title', this.props.texts.pause );
		this.refs.toggleVideoBtn.setAttribute( 'aria-label', this.props.texts.pause );
		this.state.isPlaying = true;
	}

	pause() {
		this.state.currentEl.pause();
		this.refs.toggleVideoIcon.setAttribute( 'xlink:href', '#play' );
		this.refs.toggleVideoBtn.setAttribute( 'title', this.props.texts.play );
		this.refs.toggleVideoBtn.setAttribute( 'aria-label', this.props.texts.play );
		this.state.isPlaying = false;
	}

	togglePlayVideo() {
		if ( this.state.isPlaying ) {
			this.pause();
		}
		else {
			this.play();
		}
	}

	switchVideos() {
		// fade out current vid
		this.state.currentEl.classList.add( FADE_OUT_CLASS );

		// Update STATE playlist idx
		this.state.current = this.state.next;
		this.state.next = ( this.state.next + 1 ) % this.props.playlist.length;

		// Update STATE video elements
		this.state.currentEl = this.state.nextEl;
		this.state.nextEl = this.refs.videos[ this.state.next % this.refs.videos.length ];

		// fade in and play next vid (which is now 'current')
		setTimeout( () => {
			this.state.currentEl.play();
			this.state.currentEl.classList.remove( FADE_OUT_CLASS );
		}, TIME_BEFORE_VIDEO_APPEARS * 1000 );
	}

	setNextVideo() {
		this.state.nextEl.innerHTML = `<source src="${ this.props.playlist[ this.state.next ].src }" type="${ this.props.playlist[ this.state.next ].type }">`;
		this.state.nextEl.load();
	}

	destroy() {
		if ( typeof this.el === 'undefined' ) {
			return;
		}

		delete window.supt.header.playlist;

		this.refs.videos.forEach( ( video ) => {
			video.removeEventListener( 'ended', this.videoEndedHandler );
			video.removeEventListener( 'timeupdate', this.videoTimeUpdate );
		} );

		this.refs.toggleVideoBtn.removeEventListener( 'click', this.togglePlayVideoHandler );
	}
}
