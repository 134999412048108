import BaseView from '../../../js/base-view';

export default class FilterSelectSingle extends BaseView {
	init() {
		// Bindings
		this.handleSubmit = this.handleSubmit.bind( this );

		// Elements
		this.refs = {
			select: this.getScopedElement( 'select' ),
		};

		this.bindEvents();

		// Return the instance for chaining
		return this;
	}

	bindEvents() {
		this.refs.select.addEventListener( 'change', this.handleSubmit );
	}

	handleSubmit() {
		this.trigger( 'filter:change', {
			detail: {
				id: this.element.id,
				selectedOptions: this.refs.select.value === ''
					? []
					: [ this.refs.select.value ],
			},
		}, false );
	}
}
