import axios from 'axios';

// HTTP Request cancelling
const httpRequests = new Map();

function requestCancelHandler( requestName, controller, id = 0 ) {
	const requestId = `${ requestName }-${ id }`;
	const previousRequest = httpRequests.get( requestId );
	if ( previousRequest ) {
		previousRequest.abort();
		httpRequests.delete( requestId );
	}
	httpRequests.set( requestId, controller );
}

export default function( {
	url,
	method = 'get',
	data = null,
	params = {},
	name = 'foo',
	auth = null,
	id = null,
	headers = {},
} ) {
	return new Promise( ( resolve, reject ) => {
		const controller = new AbortController();
		requestCancelHandler( name, controller );

		if ( headers[ 'Content-Type' ] === 'application/x-ndjson' ) {
			// eslint-disable-next-line no-param-reassign
			data = `${ data.map( ( item ) => JSON.stringify( item ) ).join( '\n' ) }\n`;
		}

		axios( {
			url,
			method,
			data,
			params,
			signal: controller.signal,
			auth,
			headers,
		} )
			.then( ( response ) => {
				resolve( { ...response, id } );
			} )
			.catch( ( error ) => {
				// Don't reject if the request was cancelled
				if ( axios.isCancel( error ) ) {
					return;
				}
				reject( error );
			} );
	} );
}
