import BaseView from '../../../js/base-view';

const OPEN_CLASS = 'is-open';
const ACTIVE_CLASS = 'is-active';
const TRIGGER_SELECTOR = '[data-trigger-toolbox]';
const FOCUSABLE_ELEMENTS_SELECTOR = '.toolbox__item';
const SELECTOR_ITEM = '.toolbox__item';

export default class Toolbox extends BaseView {
	init() {
		// States
		this.state = new Proxy( { isOpen: false }, { set: this.stateChange.bind( this ) } );

		// DOM element reference
		this.refs = {
			trigger: document.querySelector( TRIGGER_SELECTOR ),
			focusableElements: this.getScopedElements( FOCUSABLE_ELEMENTS_SELECTOR ),
		};

		// Store the element that had focus before opening the toolbox
		this.previousActiveElement = null;
	}

	stateChange( state, property, value ) {
		// eslint-disable-next-line no-param-reassign
		state[ property ] = value;

		// eslint-disable-next-line default-case
		switch ( property ) {
			case 'isOpen':
				this.element.classList[ state.isOpen ? 'add' : 'remove' ]( OPEN_CLASS );
				this.refs.trigger.classList[ state.isOpen ? 'add' : 'remove' ]( ACTIVE_CLASS );
				break;
		}

		return true;
	}

	bind() {
		this.init();

		if ( this.refs.trigger === null ) {
			return;
		}

		this.toggleHandler = this.toggle.bind( this );
		this.handleKeydown = this.onKeydown.bind( this );
		this.handleDocumentClick = this.handleDocumentClick.bind( this );

		this.refs.trigger.addEventListener( 'click', this.toggleHandler );
		document.addEventListener( 'keydown', this.handleKeydown );
		document.addEventListener( 'click', this.handleDocumentClick );

		this.on( 'click', SELECTOR_ITEM, this.onItemClick.bind( this ) );
	}

	onKeydown( event ) {
		if ( ! this.state.isOpen ) {
			return;
		}

		if ( event.key === 'Escape' ) {
			event.preventDefault();
			this.state.isOpen = false;
			this.refs.trigger.focus();

			// Disable focus on all focusable elements
			this.refs.focusableElements.forEach( ( element ) => {
				element.setAttribute( 'tabindex', '-1' );
			} );
			return;
		}

		if ( event.key === 'Tab' ) {
			// Get all focusable elements
			const focusableElements = Array.from( this.refs.focusableElements );
			const firstElement = focusableElements[ 0 ];
			const lastElement = focusableElements[ focusableElements.length - 1 ];

			// If shift + tab and first element is focused, move to last element
			if ( event.shiftKey && document.activeElement === firstElement ) {
				event.preventDefault();
				lastElement.focus();
			}
			// If tab and last element is focused, move to first element
			else if ( ! event.shiftKey && document.activeElement === lastElement ) {
				event.preventDefault();
				firstElement.focus();
			}
		}
	}

	toggle( event ) {
		event.preventDefault();
		this.state.isOpen = ! this.state.isOpen;

		if ( this.state.isOpen ) {
			// Store the current active element before focusing the toolbox
			this.previousActiveElement = document.activeElement;

			// Enable focus on all focusable elements
			this.refs.focusableElements.forEach( ( element ) => {
				element.setAttribute( 'tabindex', '0' );
			} );

			// Focus the first element
			this.refs.focusableElements[ 0 ].focus();
		}
		else {
			// Disable focus on all focusable elements
			this.refs.focusableElements.forEach( ( element ) => {
				element.setAttribute( 'tabindex', '-1' );
			} );

			// Return focus to the previous element
			if ( this.previousActiveElement ) {
				this.previousActiveElement.focus();
			}
		}
	}

	onItemClick() {
		this.state.isOpen = false;
	}

	handleDocumentClick( event ) {
		if ( this.state.isOpen ) {
			if ( ! event.target.closest( TRIGGER_SELECTOR ) ) {
				this.toggle( event );
			}
		}
	}

	destroy() {
		if ( this.refs.trigger ) {
			this.refs.trigger.removeEventListener( 'click', this.toggleHandler );
		}

		document.removeEventListener( 'keydown', this.handleKeydown );
		document.removeEventListener( 'click', this.handleDocumentClick );

		super.destroy();
	}
}
