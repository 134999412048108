/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { isEmpty } from 'lodash-es';

import BaseView from '../../../js/base-view';
import requester from '../../../js/utils/requester';

import SearchBar from '../../organisms/search-bar/search-bar';
import ResultList from '../../organisms/result-list/result-list';

const SELECTOR_SEARCH_BAR = '[data-search-bar]';
const SELECTOR_RESULT_LIST = '[data-result-list]';

const DEFAULT_CONFIG = {
	json_base: 'http://localhost/wp-json',
	nbPerPage: 6,
	lang: 'fr',
	ot: null, // "ot" for "Auth",
};

const DEFAULT_STATE = {
	page: 1,
	searchValue: null,
	searchFilter: null,
	nbResults: 0,
};

const CURRENT_LANG = (
	( window.supt && window.supt.lang ) ? window.supt.lang : DEFAULT_CONFIG.lang
);

export default class PageProjectList extends BaseView {
	// #######################
	// #region Init
	// #######################

	initialize() {
		this.config = { ...DEFAULT_CONFIG, ...window.supt.projects, lang: CURRENT_LANG };

		this.refs = {
			searchBar: new SearchBar( this.element.querySelector( SELECTOR_SEARCH_BAR ) ).init(),
			projectList: new ResultList( this.element.querySelector( SELECTOR_RESULT_LIST ) )
				.init( { renderCard: this.renderCard } ),
		};

		this.state = new Proxy( { ...DEFAULT_STATE }, { set: this.stateChange.bind( this ) } );

		this.bindEvents();
	}

	/**
	 * Trap handler for the state object
	 *
	 * @param {Object} state Current state object
	 * @param {string} property The name of the property to set in the state
	 * @param {any} value The new value of the property to set
	 *
	 * @return {boolean} Indicate wether or not the assignment succeeded
	 */
	stateChange( state, property, value ) {
		if ( state[ property ] === value ) {
			return true;
		}
		state[ property ] = value;

		switch ( property ) {
			case 'searchValue':
				state.page = 1;
				state.searchFilter = null;
				break;

			case 'searchFilter':
				state.page = 1;
				break;

			case 'nbResults':
				this.refs.searchBar.updateInputNbResults( value );
				break;

			case 'page':
			default:
				// nothing to do
				break;
		}
		return true;
	}

	bindEvents() {
		this.on( 'search:change', SELECTOR_SEARCH_BAR, this.onSearchChange.bind( this ) );
		this.on( 'search:submit', SELECTOR_SEARCH_BAR, this.onSubmit.bind( this ) );

		this.on( 'next-page', SELECTOR_RESULT_LIST, this.onNextPage.bind( this ) );
	}

	// #######################
	// #endregion
	// #######################

	// #######################
	// #region Event Handlers
	// #######################

	onSearchChange( { detail } ) {
		this.state.searchValue = detail;

		this.query( true );
	}

	onSubmit( { detail } ) {
		this.state.searchValue = detail.text;
		this.state.searchFilter = detail.filter;

		this.query();
	}

	onNextPage() {
		this.state.page += 1;

		this.query();
	}

	// #######################
	// #endregion
	// #######################

	// #######################
	// #region ACTIONS
	// #######################

	query( isSuggestion = false ) {
		const terms = this.getTerms();
		const params = {
			type: 'post',
			subtype: 'page',
			per_page: this.config.nbPerPage,
			page: this.state.page,
			search: this.state.searchValue,
			suggest: isSuggestion,
			...terms,
		};

		this.state.queryId = performance.now();

		requester( {
			name: 'pageProjectList:query',
			url: `${ this.config.json_base }/supt/v1/projects`,
			method: 'get',
			params,
			id: this.state.queryId,
		} )
			.then( ( { id, data } ) => {
				// Do not process if it's not the latest request
				if ( id !== this.state.queryId ) {
					return;
				}

				if ( isSuggestion ) {
					this.refs.searchBar.updateInputSuggestions(
						data.items.map( ( item ) => ( { value: item.data.title } ) )
					);
				}
				else {
					// Only show results number when not suggesting
					this.state.nbResults = ( isEmpty( this.state.searchValue ) && isEmpty( terms ) )
						? 0
						: data.total;

					const hasMoreItemsToLoad = data.max_pages > this.state.page;
					this.refs.projectList[ ( this.state.page > 1 ) ? 'addPage' : 'setList' ]( data.items, ! hasMoreItemsToLoad );

					const filterTypes = {
						status: data.aggregations.status,
						thematics: data.aggregations.thematics,
					};
					Object.entries( filterTypes ).forEach( ( [ filterType, items ] ) => {
						this.refs.searchBar.updateFilters( filterType, items );
					} );
				}
			} );
	}

	getTerms() {
		const terms = {};
		if ( ! isEmpty( this.state.searchFilter ) ) {
			Object.keys( this.state.searchFilter )
				.filter( ( name ) => ! isEmpty( this.state.searchFilter[ name ] ) )
				.forEach( ( name ) => {
					terms[ name ] = this.state.searchFilter[ name ];
				} );
		}

		return terms;
	}

	renderCard( { modifiers = [], data = {} } ) {
		const hlevel = `h${ data?.level || 2 }`;
		return `
		<article class="card-research appear-enter${ data.is_done ? ' card-research--done' : '' } ${ modifiers.join( ' ' ) }">
			<div class="card-research__inner">
				<div class="card-research__header">
					<span class="card-research__icon">
						${ data.icon === 'phd' ? '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"><path d="M3 7V31H23M29 25V5H22.5M29 25L23 31M29 25H23V31M7 25.5H19M7 20.5H19M6.38095 8.4V14.4L9.11442 15.0143C12.0021 15.6634 14.9979 15.6634 17.8856 15.0143L20.619 14.4V8.4M23.6638 7.13333V12.2667M25 5.73333L13.5 2L2 5.73333V6.8L13.5 10.5333L25 6.8V5.73333Z" stroke="black" stroke-width="2"/></svg>' : '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"><path d="M20 15H25M20 10H25M16.2658 5H29V25M29 25L23 31M29 25H23V31M23 31H3V12.5M7 25.5H17M7 20.5H17M15.4787 14.0574L21.4213 20M17.1635 8.58175C17.1635 12.769 13.769 16.1635 9.58175 16.1635C5.39446 16.1635 2 12.769 2 8.58175C2 4.39446 5.39446 1 9.58175 1C13.769 1 17.1635 4.39446 17.1635 8.58175Z" stroke="black" stroke-width="2"></path></svg>' }
					</span>
					<${ hlevel } class="card-research__title">${ data.title }</${ hlevel }>
				</div>
				<div class="card-research__content">
					<span class="tag tag--big card-research__status"><span class="tag__text">${ data.status }</span></span>
					<ul class="card-research__details">
					${ data.details.map( ( detail ) => ( detail.description === '' && detail.title === '' ? '' : `
						<li class="card-research__detail-item">
							<span class="card-research__detail-title">${ detail.title }</span>
							<span class="card-research__detail-desc">${ detail.description }</span>
						</li>` ) ).join( '' ) }
					</ul>
				</div>
				<div class="card-research__link-wrapper">
					<a
						class="card-research__link"
						href="${ data.link.href }"
						${ data.link.attrs }
					>
						<span>${ data.link.title }</span>
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
							<path d="M19.5 12.0421L12.5 5M19.5 12.0421L12.5 19M19.5 12.0421L4 12.0421" stroke="currentColor" stroke-width="1.5"></path>
						</svg>
					</a>
				</div>
			</div>
		</article>`;
	}

	// #######################
	// #endregion
	// #######################
}
