import { isEmpty } from 'lodash-es';

import BaseView from '../../../js/base-view';
import requester from '../../../js/utils/requester';

import ResultList from '../../organisms/result-list/result-list';
import SearchBar from '../../organisms/search-bar/search-bar';

const SELECTOR_SEARCH_BAR = '[data-search-bar]';
const SELECTOR_RESULT_LIST = '[data-result-list]';

const DEFAULT_CONFIG = {
	elasticHost: 'http://localhost:9200',
	index: 'posts',
	nbPerPage: 9,
	lang: 'fr',
	auth: null,
	queryParams: {
		categories: 'thematiques',
		tags: 'annees',
	},
};

const DEFAULT_STATE = {
	page: 1,
	search: null,
	filters: {
		categories: [],
		tags: [],
	},
	showNbResults: false,
	isLoading: false,
};

export default class PageArchiveModel extends BaseView {
	// #######################
	// #region Init
	// #######################

	initialize() {
		// get config from the global scope
		// NOTE: we do this because we need to pass json data from views (for the "query" parameter),
		//       and it's quite tricky and error-prone to pass it through html data-attributes
		//       (mostly because escaping is not consistent between js/php)
		if ( ! window?.supt?.archive ) {
			console.error( 'Could not load config' );
			return false;
		}

		this.config = {
			lang: window?.supt?.lang ?? null,
			...DEFAULT_CONFIG,
			...window.supt.archive,
		};

		this.renderCard = this.renderCard.bind( this );

		this.refs = {
			searchBar: new SearchBar( this.element.querySelector( SELECTOR_SEARCH_BAR ) ).init(),
			resultList: new ResultList( this.element.querySelector( SELECTOR_RESULT_LIST ) )
				.init( { renderCard: this.renderCard } ),
		};

		this.state = new Proxy(
			this.getInitialState(),
			{ set: this.stateChange.bind( this ) },
		);

		this.bindEvents();

		return true;
	}

	/**
	 * Trap handler for the state object
	 *
	 * @param {Object} state Current state object
	 * @param {string} property The name of the property to set in the state
	 * @param {*} value The new value of the property to set
	 *
	 * @return {boolean} Indicate wether or not the assignment succeeded
	 */
	stateChange( state, property, value ) {
		/* eslint-disable no-param-reassign */
		if ( state[ property ] === value ) {
			return true;
		}
		state[ property ] = value;

		switch ( property ) {
			case 'search':
				state.page = 1;
				state.filters = null;
				break;

			case 'filter':
				state.page = 1;
				break;

			case 'isLoading':
				this.element.classList.toggle( 'is-loading', value );
				break;

			case 'page':
			default:
				// nothing to do
				break;
		}
		return true;
	}

	bindEvents() {
		this.on( 'search:change', SELECTOR_SEARCH_BAR, this.onSearchChange.bind( this ) );
		this.on( 'search:submit', SELECTOR_SEARCH_BAR, this.onSubmit.bind( this ) );

		this.on( 'next-page', SELECTOR_RESULT_LIST, this.onNextPage.bind( this ) );
	}

	destroy() {
		this.refs.searchBar.destroy();
		this.refs.resultList.destroy();

		super.destroy();
	}

	getFilterItems( id, callback = null ) {
		const options = this.element.querySelectorAll( `[data-filter-select]#${ id } li[role="option"]` );

		return Array.from( options ).reduce( ( acc, o ) => ( {
			...acc,
			[ o.dataset.value ]: callback ? callback( o ) : o.textContent.trim(),
		} ), {} );
	}

	// #######################
	// #endregion
	// #######################

	// #######################
	// #region Event Handlers
	// #######################

	onSearchChange( { detail } ) {
		this.state.search = detail;

		this.suggest();
	}

	async onSubmit( { detail } ) {
		let changed = false;

		changed = this.handleSearchChange( detail.text ) || changed;
		changed = this.handleFiltersChange( detail.filters ) || changed;

		if ( changed ) {
			this.request();
		}
	}

	async onNextPage() {
		this.state.page += 1;

		this.request();
	}

	async request() {
		this.state.isLoading = true;

		if ( this.config.elasticHost !== false ) {
			await this.query();
		}
		else {
			await this.fetch();
		}

		this.updateQueryString();

		this.state.isLoading = false;
	}

	// #######################
	// #endregion
	// #######################

	// #######################
	// #region ABSTRACT METHODS
	// #######################

	getInitialState() {
		const queryArgs = new URLSearchParams( window.location.search );

		return {
			...DEFAULT_STATE,
			filters: Object.entries( this.config.queryParams ).reduce( ( acc, [ key, name ] ) => {
				acc[ key ] = queryArgs.get( name )?.split( ',' ) ?? [];
				return acc;
			}, {} ),
		};
	}

	handleSearchChange( newSearch ) {
		if ( newSearch !== null ) {
			this.state.search = newSearch;
		}

		return true;
	}

	handleFiltersChange( newFilters ) {
		this.state.filters = {
			...this.state.filters,
			...newFilters,
		};

		return true;
	}

	/**
	 * Fetches and transforms profile information from Elasticsearch
	 *
	 * @param {Array<string>} ids - Array of profile IDs to fetch from Elasticsearch
	 * @return {Promise<Object>} A promise that resolves to an object where:
	 *   - key: profile ID
	 *   - value: {Object} containing:
	 *     - name: {string} The full name of the profile
	 *     - link: {string} The profile's URL
	 *     - isCurrentlyActive: {boolean} Whether the profile is currently active based on
	 *       end_contract date
	 */
	async getProfiles( ids ) {
		if ( isEmpty( ids ) ) {
			return {};
		}

		const args = {
			name: 'pageArchive:getProfiles',
			url: `${ this.config.elasticHost }/profiles/_mget`,
			method: 'post',
			data: {
				ids,
			},
		};

		if ( ! isEmpty( this.config.auth ) ) {
			args.auth = {
				username: this.config.auth.u,
				password: this.config.auth.p,
			};
		}

		const response = await requester( args )
			.catch( ( error ) => {
				console.error( error );
			} );

		const today = new Date();
		return response.data.docs.reduce( ( acc, { _source: s } ) => {
			acc[ s.id ] = {
				name: s.fullname,
				link: `${ this.config.baseUrlProfile }/${ s.slug }/`,
				isCurrentlyActive: ! s.end_contract || today <= new Date( s.end_contract ),
			};

			return acc;
		}, {} );
	}

	/**
	 * Get the aggregations for the query
	 *
	 * @return {Object} The aggregations property
	 */
	getAggs() {
		throw new Error( 'Not implemented' );

		// eslint-disable-next-line no-unreachable
		return {};
	}

	/**
	 * Get the query for the elasticSearch request
	 *
	 * @return {Object} The query property
	 */
	getQuery() {
		throw new Error( 'Not implemented' );

		// eslint-disable-next-line no-unreachable
		return {};
	}

	/**
	 * Get the sort for the query
	 *
	 * @return {Array} The sort property
	 */
	getSort() {
		throw new Error( 'Not implemented' );

		// eslint-disable-next-line no-unreachable
		return [];
	}

	async prepareHits( hits ) {
		return hits;
	}

	async preparePosts( posts ) {
		return posts;
	}

	/**
	 * Get the card context for a given post
	 *
	 * @param {Object} document The elasticSearch document
	 *
	 * @return {Object} The card context
	 */
	// eslint-disable-next-line no-unused-vars
	async getCardContext( { _source: source } ) {
		throw new Error( 'Not implemented' );
	}

	/**
	 * Render a card
	 *
	 * @param {Object} param0 The card context data
	 *                        {
	 *                          modifiers: {Array} class modifiers
	 *                          data: {Object} card data
	 *                        }
	 */
	// eslint-disable-next-line no-unused-vars
	renderCard( { modifiers = [], data = {} } ) {
		throw new Error( 'Not implemented' );
	}

	getQueryString( restApiFormat = false ) {
		const { queryParams } = this.config;
		const args = [];

		if ( restApiFormat ) {
			args.push( `page=${ this.state.page }` );
			args.push( `per_page=${ this.config.nbPerPage }` );
		}

		// search
		if ( ! isEmpty( this.state.search ) ) {
			args.push( `q=${ encodeURIComponent( this.state.search ) }` );
		}

		// filters
		Object.entries( this.state.filters ).forEach( ( [ key, value ] ) => {
			if ( ! isEmpty( value ) ) {
				args.push( `${ queryParams[ key ] }=${ value.sort().join( ',' ) }` );
			}
		} );

		return args.length ? args.join( '&' ) : '';
	}

	// #######################
	// #endregion
	// #######################

	// #######################
	// #region BUSINESS LOGIC
	// #######################

	/**
	 * Suggest the search query
	 */
	suggest() {
		// TODO: not needed for now
	}

	/**
	 * Query ElasticSearch index
	 */
	async query() {
		// const aggs = this.getAggs();
		const query = this.getQuery();
		const sort = this.getSort();

		const fromIndex = this.config.nbPerPage * ( this.state.page - 1 );

		const args = {
			name: 'pageArchive:query',
			url: `${ this.config.elasticHost }/${ this.config.index }/_search`,
			method: 'post',
			data: {
				query,
				size: this.config.nbPerPage,
				from: fromIndex,
				sort,
				// aggs,
			},
		};

		if ( ! isEmpty( this.config.auth ) ) {
			args.auth = {
				username: this.config.auth.u,
				password: this.config.auth.p,
			};
		}

		const { data } = await requester( args );

		const hits = await this.prepareHits( data.hits.hits );
		const results = hits.map( this.getCardContext.bind( this ) );

		const hasMoreItemsToLoad = ( fromIndex + results.length < data.hits.total.value );

		this.refs.resultList[ ( this.state.page > 1 ? 'addPage' : 'setList' ) ]( results, ! hasMoreItemsToLoad );

		this.refs.searchBar.updateInputNbResults(
			( query?.bool?.must?.length ?? 0 ) > 0
				? data.hits.total.value
				: 0
		);

		// this.refs.searchBar.updateFilters(
		// 	Object.entries( data.aggregations )
		// 		.reduce( ( acc, [ key, value ] ) => 	(
		// 			{ ...acc, [ key ]: value.unique.buckets.map( ( bucket ) => bucket.key ) }
		// 		), {} ),
		// );
	}

	/**
	 * Fetch posts from WordPress REST API
	 */
	async fetch() {
		const fromIndex = this.config.nbPerPage * ( this.state.page - 1 );

		const { data, headers } = await requester( {
			name: 'pageArchive:fetch',
			url: `/wp-json/wp/v2/${ this.config.index }?${ this.getQueryString( true ) }`,
			method: 'get',

		} );

		const posts = await this.preparePosts( data );
		const results = posts.map( ( p ) => this.getCardContext( { _source: p } ) );

		const totalPosts = parseInt( headers[ 'x-wp-total' ], 10 );

		const hasMoreItemsToLoad = ( fromIndex + results.length < totalPosts );

		this.refs.resultList[ ( this.state.page > 1 ? 'addPage' : 'setList' ) ]( results, ! hasMoreItemsToLoad );

		this.refs.searchBar.updateInputNbResults( totalPosts );
	}

	updateQueryString() {
		const queryString = this.getQueryString();
		const newUrl = queryString !== '' ? `${ window.location.pathname }?${ queryString }` : window.location.pathname;
		if ( newUrl !== window.location.href ) {
			window.history.pushState( null, '', newUrl );
		}
	}

	// #######################
	// #endregion
	// #######################
}
