import { isEmpty } from 'lodash-es';

import BaseArchiveModel from './page-archive--model';
import requester from '../../../js/utils/requester';

export default class PageArchivePosts extends BaseArchiveModel {
	getAggs() {
		return {
			categories: {
				nested: { path: 'categories' },
				aggs: {
					unique: {
						terms: { field: 'categories.slug', size: 1000, order: { _key: 'asc' } },
						aggs: { title: { terms: { field: 'categories.title', size: 1 } } },
					},
				},
			},
			tags: {
				nested: { path: 'tags' },
				aggs: {
					unique: {
						terms: { field: 'tags.slug', size: 1000, order: { _key: 'asc' } },
						aggs: { title: { terms: { field: 'tags.title', size: 1 } } },
					},
				},
			},
		};
	}

	getQuery() {
		// Empty
		const queryArgs = [];

		// search text
		if ( ! isEmpty( this.state.search ) ) {
			queryArgs.push( {
				bool: {
					must: [
						{ match: { title: { query: this.state.search, fuzziness: 1 } } },
					],
				},
			} );
		}

		// Filters selected
		if ( ! isEmpty( this.state.filters ) ) {
			Object.keys( this.state.filters )
				.filter( ( k ) => ! isEmpty( this.state.filters[ k ] ) )
				.forEach( ( k ) => {
					queryArgs.push( {
						nested: {
							path: k,
							query: {
								bool: {
									must: [
										{
											terms: {
												[ `${ k }.slug` ]: this.state.filters[ k ],
											},
										},
									],
								},
							},
						},
					} );
				} );
		}

		return {
			bool: {
				must: queryArgs,
			},
		};
	}

	getSort() {
		const sort = [
			{ date: 'desc' },
		];

		if ( ! isEmpty( this.state.search ) ) {
			sort.unshift( '_score' );
		}

		return sort;
	}

	getCardContext( { _source: source } ) {
		const primaryCategory = source.categories.find( ( c ) => c.primary ) ?? source.categories[ 0 ];
		return {
			modifiers: [],
			data: {
				header: {
					title: 'Actualité',
					icon: 'article',
					tag: primaryCategory ? {
						text: primaryCategory.title,
						link: primaryCategory.url,
					} : null,
				},
				title: source.title,
				image: source.image,
				link: source.url,
				button: {
					title: 'Lire l\'article',
				},
			},
		};
	}

	async preparePosts( posts ) {
		const medias = {
			requested: [],
			promises: [],
		};
		const categories = {
			requested: [],
			promises: [],
		};

		posts.forEach( ( p ) => {
			if ( p.featured_media !== 0 && ! medias.requested.includes( p.featured_media ) ) {
				medias.requested.push( p.featured_media );
				medias.promises.push( this.fetchMedia( p.featured_media ) );
			}

			p.categories.forEach( ( c ) => {
				if ( ! categories.requested.includes( c ) ) {
					categories.requested.push( c );
					categories.promises.push( this.fetchCategory( c ) );
				}
			} );
		} );

		await Promise.allSettled( medias.promises )
			.then( ( res ) => res.forEach( ( r ) => {
				if ( r.status === 'fulfilled' && r.value ) {
					medias[ r.value.id ] = r.value;
				}
			} ) );

		await Promise.allSettled( categories.promises )
			.then( ( res ) => res.forEach( ( r ) => {
				if ( r.status === 'fulfilled' && r.value ) {
					categories[ r.value.id ] = r.value;
				}
			} ) );

		return posts.map( ( p ) => {
			const media = medias?.[ p.featured_media ] ?? null;
			const cats = p.categories.reduce( ( acc, c, i ) => {
				const term = categories?.[ c ] ?? null;

				if ( term ) {
					acc.push( {
						primary: i === 0,
						slug: term.slug,
						title: term.name,
						url: `${ window.location.origin }${ window.location.pathname }?${ this.config.queryParams.categories }=${ term.slug }`,
					} );
				}
				return acc;
			}, [] );

			return {
				id: p.id,
				categories: cats,
				title: p.title.rendered,
				image: media ? {
					src: media.source_url,
					picture: media.picture,
				} : null,
				url: p.link,
			};
		} );
	}

	async fetchCategory( id ) {
		const args = {
			name: `pageArchivePosts:fetchCategory:${ id }`,
			url: `/wp-json/wp/v2/categories/${ id }`,
			method: 'get',
		};
		const { data } = await requester( args );

		return {
			...data,
			primary: false,
		};
	}

	async fetchMedia( id ) {
		if ( id === 0 ) {
			return null;
		}

		const args = {
			name: `pageArchivePosts:fetchMedia:${ id }`,
			url: `/wp-json/wp/v2/media/${ id }?size=card`,
			method: 'get',
		};
		const { data } = await requester( args );

		return data;
	}

	renderCard( { modifiers = [], data = {} } ) {
		const tag = `<span class="tag">
			${ data?.header?.tag ? `<a href="${ data.header.tag.link }" class="tag__text" rel="nofollow">${ data.header.tag.text }</a>` : `<span class="tag__text">${ data.header.title }</span>` }
		</span>`;

		return `
		<article class="card-article ${ modifiers.join( ' ' ) }">
			<div class="card-article__inner">
				<div class="card-article__header">
					${ tag }
					<div class="card-article__image">
						${ data?.image?.picture ? `<picture>${ data.image?.picture }</picture>` : '' }
					</div>
					<div class="card-article__header_icon">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" svg role="img">
							<title>{{ data.header.title }</title>
							<path d="M17.25 21.75L21.75 17.2498M17.25 21.75H2.25V2.24982H21.75V17.2498M17.25 21.75V17.25L21.75 17.2498M13.5 9.75037H18.75M5.25 13.5004H18.75M5.25 17.2504H14.25M13.5 6.00037H18.75M10.5 9.75H6V6H10.5V9.75Z" stroke="currentColor" stroke-width="1.5"/>
						</svg>
					</div>
				</div>
				<div class="card-article__content">
					<h2 class="card-article__title">${ data.title }</h2>
					<div class="wp-block-button is-style-secondary">
						<a
							class="wp-block-button__link"
							href="${ data.link }"
						>
							${ data.button.title }
						</a>
					</div>
				</div>
			</div>
		</article>`;
	}
}
