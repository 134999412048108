/* eslint-disable no-underscore-dangle */
import { isEmpty } from 'lodash-es';

import BaseArchiveModel from './page-archive--model';

export default class PageArchivePublications extends BaseArchiveModel {
	initialize() {
		super.initialize();

		this.authorsItems = this.getFilterItems( 'authors', ( o ) => parseInt( o.id ) );
		this.categoriesItems = this.getFilterItems( 'categories' );
		this.datesItems = this.getFilterItems( 'dates', ( o ) => o.id );
	}

	getInitialState() {
		const queryArgs = new URLSearchParams( window.location.search );

		return {
			...super.getInitialState(),
			search: queryArgs.get( 'q' ) ?? '',
		};
	}

	getAggs() {
		return {
			categories: {
				global: {},
				aggs: {
					wordpress: {
						nested: {
							path: 'wordpress',
						},
						aggs: {
							unique: {
								terms: {
									field: 'wordpress.category',
									size: 1000,
									order: { _key: 'asc' },
								},
							},
						},
					},
					root: {
						terms: {
							field: 'cat',
							size: 1000,
							order: { _key: 'asc' },
						},
					},
				},
			},
			authors: {
				global: {},
				aggs: {
					wordpress: {
						nested: {
							path: 'wordpress',
						},
						aggs: {
							unique: {
								terms: {
									field: 'wordpress.authors',
									size: 1000,
									order: { _key: 'asc' },
								},
							},
						},
					},
					root: {
						terms: {
							field: 'author_ids',
							size: 1000,
							order: { _key: 'asc' },
						},
					},
				},
			},
			dates: {
				global: {},
				aggs: {
					wordpress: {
						nested: {
							path: 'wordpress',
						},
						aggs: {
							unique: {
								date_histogram: {
									field: 'wordpress.date',
									calendar_interval: 'month',
									format: 'yyyy-MM',
									order: { _key: 'desc' },
								},
							},
						},
					},
					root: {
						date_histogram: {
							field: 'date',
							calendar_interval: 'month',
							format: 'yyyy-MM',
							order: { _key: 'desc' },
						},
					},
				},
			},
		};
	}

	getQuery() {
		// Empty
		const queryArgs = [];

		// search text
		if ( ! isEmpty( this.state.search ) ) {
			// Escape special characters in the search query
			const sanitizedSearch = this.state.search.replace(
				// eslint-disable-next-line no-useless-escape
				/[/:!^$?.*(){}&"<>#@+|~\[\]]/g,
				'\\$&',
			);

			queryArgs.push( {
				bool: {
					should: [
						{ match: { title: { query: sanitizedSearch, fuzziness: 1 } } },
						{
							nested: {
								path: 'wordpress',
								query: {
									match: {
										'wordpress.title': {
											query: sanitizedSearch,
											fuzziness: 1,
										},
									},
								},
							},
						},
					],
				},
			} );
		}

		// Filters selected
		if ( ! isEmpty( this.state.filters.categories ) ) {
			queryArgs.push( {
				terms: {
					cat: this.state.filters.categories.map( ( v ) => this.categoriesItems[ v ] ),
				},
			} );
		}
		if ( ! isEmpty( this.state.filters.dates ) ) {
			queryArgs.push( {
				bool: {
					should: this.state.filters.dates.map( ( v ) => {
						const monthDate = this.datesItems[ v ];
						return {
							range: {
								date: {
									gte: monthDate,
									lt: `${ monthDate }||+1M`,
								},
							},
						};
					} ),
				},
			} );
		}
		if ( ! isEmpty( this.state.filters.authors ) ) {
			queryArgs.push( {
				terms: {
					author_ids: this.state.filters.authors.map( ( v ) => this.authorsItems[ v ] ),
				},
			} );
		}

		return {
			bool: {
				must: queryArgs,
			},
		};
	}

	getSort() {
		const sort = [
			{ date: 'desc' },
		];

		if ( ! isEmpty( this.state.search ) ) {
			sort.unshift( '_score' );
		}

		return sort;
	}

	async prepareHits( hits ) {
		if ( hits.length === 0 ) {
			return [];
		}

		const profiles = await this.getProfiles(
			hits.reduce( ( acc, { _source: s } ) => {
				( s.wordpress?.authors ?? s.author_ids ?? [] ).forEach( ( id ) => {
					if ( ! acc.includes( id ) ) {
						acc.push( id );
					}
				} );

				return acc;
			}, [] ),
		);

		const finalHits = hits.map( ( hit ) => ( {
			...hit,
			_source: {
				...hit._source,
				authors: ( hit._source?.wordpress?.authors ?? hit._source.author_ids ?? [] )
					.map( ( id ) => profiles[ id ] ),
			},
		} ) );

		return finalHits;
	}

	getCardContext( { _source: source } ) {
		let date = source?.wordpress?.date ?? source.date;
		if ( date ) {
			date = new Date( date );
			date = date.toLocaleDateString( 'fr-FR', { month: 'long', year: 'numeric' } );
			date = date.charAt( 0 ).toUpperCase() + date.slice( 1 );
		}

		return {
			modifiers: [ source.link ? 'card-publication--link' : false ].filter( Boolean ),
			data: {
				title: source?.wordpress?.title ?? source.title,
				link: source?.wordpress?.link ?? source.link,
				authors: source.authors,
				date,
			},
		};
	}

	renderCard( { modifiers = [], data = {} } ) {
		return `
		<article class="card-publication ${ modifiers.join( ' ' ) }">
			<div class="card-publication__inner">

				<div class="card-publication__header">
					<span class="card-publication__icon">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none" role="img">
							<path d="M23 29.5L29 23.4998M23 29.5H3V3.49976H29V23.4998M23 29.5V23.5L29 23.4998M7 13.5005H25M7 18.5005H19M7 8.50049H25" stroke="currentColor" stroke-width="2"/>
						</svg>
					</span>
					<p class="card-publication__title">${ data.title }</p>
				</div>

				<div class="card-publication__content">
					<ul class="card-publication__details">
					${ data.authors.length > 0 ? `
						<li class="card-publication__detail-item">
							<span class="card-publication__detail-title">Auteurs</span>
							<span class="card-publication__detail-desc">
								${ data.authors.map( ( a ) => ( a.isCurrentlyActive ? `<a href="${ a.link }" target="_blank">${ a.name }</a>` : a.name ) ).join( ', ' ) }
							</span>
						</li>
						` : '' }
					${ data.date ? `
						<li class="card-publication__detail-item">
							<span class="card-publication__detail-title">Date</span>
							<span class="card-publication__detail-desc">${ data.date }</span>
						</li>
					` : '' }
					</ul>
				</div>

				${ data.link ? `
					<div class="card-publication__link-wrapper">
						<a class="card-publication__link" href="${ data.link }" target="_blank" rel="noopener noreferrer">
							<span>Lire l'article</span>
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" role="img">
								<path d="M7.2 6H18M18 6V16.8M18 6L6 18" stroke="currentColor" stroke-width="1.5"/>
							</svg>
						</a>
					</div>
				` : '' }
			</div>
		</article>`;
	}
}
