/* eslint-disable object-curly-newline */
/**
 * Auto injected components Imports
 * ↓ please keep comments (starting with `// ===`) to keep the auto insertion working
 */
// === Imports ===
import ArticleListProfile from './organisms/article-list/article-list--profile';
import ArticleSlider from './organisms/articles-slider/articles-slider';
import Breadcrumbs from './molecules/breadcrumbs/breadcrumbs';
import Button from './atoms/button/button';
import Form from './organisms/form/form';
import Gallery from './wp-blocks/gallery/gallery';
import Header from './organisms/header/header';
import InitialAppearance from './1-javascript/initial-appearance/initial-appearance';
import Modal from './organisms/modal/modal';
import Navigation from './organisms/navigation/navigation';
import PageArchive from './templates/03-page-archive/page-archive';
import PageAnnuaire from './templates/09-page-annuaire/page-annuaire';
import PageProjectList from './templates/10-page-project-list/page-project-list';
import PagePublicationList from './templates/11-page-publication-list/page-publication-list';
import ProfileNav from './organisms/profile-nav/profile-nav';
import ProfileSubmenu from './molecules/profile-submenu/profile-submenu';
import ResultListProfile from './organisms/result-list/result-list--profile';
import SectionCardResearch from './organisms/section-card-research/section-card-research';
import Toolbox from './molecules/toolbox/toolbox';
import Video from './molecules/video/video';
import WeeklySchedule from './organisms/weekly-schedule/weekly-schedule';

// === Loader Object ===
export default [
	{ view: ArticleListProfile, name: 'ArticleListProfile', selector: '[data-article-list-profile]' },
	{ view: ArticleSlider, name: 'ArticleSlider', selector: '[data-article-slider]' },
	{ view: Breadcrumbs, name: 'Breadcrumbs', selector: '[data-breadcrumbs]' },
	{ view: Button, name: 'Button', selector: '[data-button]' },
	{ view: Form, name: 'Form', selector: '[data-form]' },
	{ view: Gallery, name: 'Gallery', selector: '[data-gallery]' },
	{ view: Header, name: 'Header', selector: '[data-header]' },
	{ view: InitialAppearance, name: 'InitialAppearance', selector: '.initial-appearance' },
	{ view: PageArchive, name: 'PageArchive', selector: '[data-page-archive-posts]' },
	{ view: PageAnnuaire, name: 'PageAnnuaire', selector: '[data-page-annuaire]' },
	{ view: PageProjectList, name: 'PageProjectList', selector: '[data-page-project-list]' },
	{ view: PagePublicationList, name: 'PublicationList', selector: '[data-page-publication-list]' },
	{ view: ProfileNav, name: 'ProfileNav', selector: '[data-profile-nav]' },
	{ view: ProfileSubmenu, name: 'ProfileSubmenu', selector: '[data-profile-submenu]' },
	{ view: Modal, name: 'Modal', selector: '[data-modal]' },
	{ view: Navigation, name: 'Navigation', selector: '[data-navigation]', global: true },
	{ view: ResultListProfile, name: 'ResultListProfile', selector: '[data-result-list-profile]' },
	{ view: SectionCardResearch, name: 'SectionCardResearch', selector: '[data-section-card-research]' },
	{ view: Toolbox, name: 'Toolbox', selector: '[data-toolbox]' },
	{ view: Video, name: 'Video', selector: '[data-video]' },
	{ view: WeeklySchedule, name: 'WeeklySchedule', selector: '[data-weekly-schedule]' },
];
