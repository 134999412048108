import BaseView from '../../../js/base-view';

const FIELD_ERROR_SELECTOR = '[data-error-text]';

const FILLED_CLASS = 'is-filled';
export default class ListChoice extends BaseView {
	init() {
		this.setIsFilled = this.setIsFilled.bind( this );

		this.props = {
			name: 'list-choice',
		};

		this.refs = {
			inputs: Array.from( this.element.querySelectorAll( 'input' ) ),
			fieldError: this.getScopedElement( FIELD_ERROR_SELECTOR ),
		};

		this.bind();
	}

	bind() {
		this.errorEl = this.element;
		// Bind change event section.
		this.refs.inputs.forEach( ( input ) => {
			this.on( 'change', input, this.setIsFilled );
		} );

		// Make sure if the input is not empty
		// the label is put in the correct state
		this.setIsFilled();
	}

	getName() {
		return this.props.name;
	}

	/**
	 * Set the state of is_filled and it's class to the element
	 */
	setIsFilled() {
		const isFilled = this.refs.inputs.some( ( input ) => input.checked );
		if ( isFilled ) {
			this.element.classList.add( FILLED_CLASS );
		} else {
			this.element.classList.remove( FILLED_CLASS );
		}
	}

	checkError() {
		if ( ! this.errorEl.hasAttribute( 'data-error' ) ) {
			return;
		}
		if ( this.refs.inputs.every( ( input ) => input.checkValidity() ) ) {
			this.resetError();
		}
	}

	reset() {
		this.resetError();
	}

	setError( error ) {
		this.element.setAttribute( 'data-error', error );
		this.refs.fieldError.innerHTML = error;
	}

	resetError() {
		this.element.removeAttribute( 'data-error' );
		this.refs.fieldError.innerHTML = '';
	}

	focus() {
		this.refs.inputs[ 0 ].focus();
	}
}
