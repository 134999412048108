import { isFunction } from 'lodash-es';

import BaseView from '../../../js/base-view';

const LIST_SELECTOR = '.result-list__list';
const PAGINATION_BUTTON_SELECTOR = '.result-list__pagination .wp-block-button__link';

const CLASS_IS_LOADING = 'is-loading';
const CLASS_NO_RESULTS = 'no-results';
const CLASS_NO_PAGINATION = 'no-pagination';

export default class ResultList extends BaseView {
	init( { renderCard = null } ) {
		// fetch config from the global scope
		// NOTE: we do this because we need to pass json data from views (for the "query" parameter),
		//       and it's quite tricky and error-prone to pass it through html data-attributes
		//       (mostly because escaping is not consistent between js/php)
		if ( ! window.supt || ! window.supt.resultListConfig ) {
			console.error( 'Could not load config' );
			return false;
		}

		this.config = window.supt.resultListConfig;

		this.refs = {
			list: this.element.querySelector( LIST_SELECTOR ),
			button: this.element.querySelector( PAGINATION_BUTTON_SELECTOR ),
		};

		this.state = new Proxy(
			{
				isLoading: false,
				maxReached: false,
				hasNoResults: false,
				hasNoPagination: false,
			},
			{ set: this.stateChange.bind( this ) },
		);

		this.bindEvents();
		this.renderCard = ( isFunction( renderCard ) ? renderCard : this.renderCardFallback );

		// Allows chaining
		return this;
	}

	/**
	 * Trap handler for the state object
	 *
	 * @param {Object} state Current state object
	 * @param {string} property The name of the property to set in the state
	 * @param {*} value The new value of the property to set
	 *
	 * @return {boolean} Indicate wether or not the assignment succeeded
	 */
	stateChange( state, property, value ) {
		// eslint-disable-next-line no-param-reassign
		state[ property ] = value; // Update the state

		// eslint-disable-next-line default-case
		switch ( property ) {
			case 'isLoading':
				this.toggleLoadingState();
				break;
			case 'maxReached':
				this.toggleMaxReachedState();
				break;
			case 'hasNoResults':
				this.toggleNoResultsState();
				break;
			case 'hasNoPagination':
				this.toggleHasNoPaginationState();
				break;
		}

		return true;
	}

	bindEvents() {
		this.on( 'click', PAGINATION_BUTTON_SELECTOR, this.onPaginationClick.bind( this ) );
	}

	/**
	 * Trigger a next-page event
	 *
	 * @param {Object} event
	 */
	onPaginationClick( event ) {
		event.preventDefault();
		this.state.isLoading = true;
		this.trigger( 'next-page', null, false );
	}

	// ########################
	// #region PUBLIC METHODS
	// ########################

	/**
	 * Prints the full list of cards (incl. "more" button) to the DOM, using the provided data.
	 *
	 * @param {Array} items List of results context data
	 * @param {boolean} noPagination Wether or not the pagination button should appear
	 */
	setList( items = [], noPagination = false ) {
		this.renderList( items );

		// Update state
		this.state.isLoading = false;
		this.state.maxReached = false;
		this.state.hasNoResults = ! items.length;
		this.state.hasNoPagination = noPagination;
	}

	/**
	 * Appends more cards to the existing list of cards,
	 * while the setList() method generates the full list with pagination…)
	 *
	 * @param {Array} items List of card-profile context data
	 * @param {boolean} maxReached Wether or not the user didn't yet reached the end of the world 🧟‍👽
	 */
	addPage( items, maxReached = false ) {
		this.renderList( items, true );

		// Update state
		this.state.isLoading = false;
		this.state.maxReached = maxReached;
	}

	// ########################
	// #endregion
	// ########################

	// ########################
	// #region DOM MANIPULATION
	// ########################

	renderCardFallback() {
		return '<div>Please provide a card render function</div>';
	}

	removeCardsNicely() {
		return new Promise( ( resolve ) => {
			const cards = Array.from( this.refs.list.children );

			cards.forEach( ( card ) => {
				card.classList.add( 'leave-enter' );
				setTimeout( () => card.classList.add( 'leave' ), 10 );
			} );

			setTimeout( () => {
				cards.forEach( ( card ) => {
					try {
						this.refs.list.removeChild( card );
					}
					catch ( e ) {
						// console.error( 'Error removing card', e );
					}
				} );
				resolve();
			}, 450 );
		} );
	}

	showCardsNicely() {
		const newCards = Array.from( this.refs.list.children );
		newCards.forEach( ( card ) => card.classList.add( 'enter' ) );
		setTimeout( () => {
			newCards.forEach( ( card ) => card.classList.remove( 'appear-enter', 'enter' ) );
		}, 450 );
	}

	/**
	 * Prints provided HTML string to target element.
	 *
	 * @param {Array} items List of card-profile context data
	 * @param {boolean} append If true, content will be appended to target;
	 *                         if false, content will REPLACE the existing target content.
	 */
	renderList( items, append = false ) {
		// Remove the content first if not appending
		const removePromise = ! append
			? this.removeCardsNicely()
			: new Promise( ( resolve ) => resolve() );

		this.refs.list.insertAdjacentHTML( 'beforeend', items.map( ( item ) => {
			const { modifiers = [], data = [] } = item;
			modifiers.push( 'appear-enter' );
			return this.renderCard( { modifiers, data } );
		} ).join( '' ) );

		removePromise.then( () => this.showCardsNicely() );
	}

	/**
	 * Set/unset loading state on the UI
	 */
	toggleLoadingState() {
		this.element.classList[ this.state.isLoading ? 'add' : 'remove' ]( CLASS_IS_LOADING );
		this.refs.button.classList[ this.state.isLoading ? 'add' : 'remove' ]( CLASS_IS_LOADING );
		this.refs.button.innerText = this.config.texts[ this.state.isLoading ? 'loading' : 'initial' ];
	}

	/**
	 * Set/unset max reached state on the UI
	 */
	toggleMaxReachedState() {
		this.refs.button[ this.state.maxReached ? 'setAttribute' : 'removeAttribute' ]( 'disabled', true );
		this.refs.button.innerText = this.config.texts[ this.state.maxReached ? 'maxReached' : 'initial' ];
	}

	/**
	 * Set/unset pagination state on the UI
	 */
	toggleHasNoPaginationState() {
		this.element.classList[ this.state.hasNoPagination ? 'add' : 'remove' ]( CLASS_NO_PAGINATION );
	}

	/**
	 * Set/unset no results state on the UI
	 */
	toggleNoResultsState() {
		this.element.classList[ this.state.hasNoResults ? 'add' : 'remove' ]( CLASS_NO_RESULTS );
	}

	// ########################
	// #endregion
	// ########################
}
